import {
    PROJECT_DETAILS_STAGE,
} from "../../containers/screens/project-details";
import {CreditSummaryStats} from "../../components/credit-summary-stats";
import {CIRCULARITY_CRITERIA_STAGE} from "../../containers/screens/circularity-criteria";
import {DESIGN_FOR_ADAPTABILITY} from "../../containers/screens/sub-screens/design-for-adaptability";
import {CreditsCalculator} from "./credits-calculator";
import {
    CIRCULAR_MATERIAL_SELECTION_PREFIX,
    DESIGN_FOR_ADAPTABILITY_PREFIX,
    DESIGN_FOR_DECONSTRUCTION_PREFIX, RESOURCE_EFFICIENCY_PREFIX
} from "./aim-required-questions";
import {DESIGN_FOR_DECONSTRUCTION} from "../../containers/screens/sub-screens/design-for-deconstruction";
import {CIRCULAR_MATERIAL_SELECTION} from "../../containers/screens/sub-screens/circular-material-selection";
import {RESOURCE_EFFICIENCY} from "../../containers/screens/sub-screens/resource-efficiency";

export class CreditSummaryStatsFactory {

    constructor(project) {
        this.project = project;
        this.calc = new CreditsCalculator(this.project);
        this.types = ['site', 'structure', 'skin', 'services', 'space'];
    }


    getType(type) {
        let stage = this.project.stages.find(PROJECT_DETAILS_STAGE);
        if (!stage) {
            return false;
        }
        let nb = stage.sections.find(type);
        if (!nb) {
            return false;
        }

        return nb;
    }


    getPrinciple(type) {
        return this.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', 'design_for_adaptability');
    }

    calculatePercentageOfAim(prefix, layer, principle, achieved) {
        let available = this.calc.calculateLayerAvailableCredits(prefix, layer, principle);

        return (achieved / available) * 100;
    }

    calculateLayerAchievedRating(prefix, layer, creditsTotal) {


        let full = this.calc.calculateLayerAvailableCredits(prefix, layer, 'full');

        if (full === creditsTotal) {
            return 'full';
        }

        let partial = this.calc.calculateLayerAvailableCredits(prefix, layer, 'partial');
        if (partial === creditsTotal) {
            return 'partial';
        }

        let basic = this.calc.calculateLayerAvailableCredits(prefix, layer, 'basic');
        if (basic === creditsTotal) {
            return 'basic';
        }

        return null;
    }

    getRequiredCreditsForRatingCategory(prefix) {
        let r = {};

        this.types.forEach(type => {
           r[type] = {
               basic :this.calc.calculateLayerAvailableCredits(prefix, type, 'basic'),
               partial :this.calc.calculateLayerAvailableCredits(prefix, type, 'partial'),
               full :this.calc.calculateLayerAvailableCredits(prefix, type, 'full')
           }
        });
        return r;
    }

    fillStatsForPrefix(category, prefix, stats) {
        let principle = this.getPrinciple(category);
        let requiredForRatings = this.getRequiredCreditsForRatingCategory(prefix);
        let totalAwardedCredits = 0;
        let totalAvailableCreditsFroAim = 0;
        let totalAvailableCredits = 0



        this.types.forEach(type => {
            let total = this.calc.calculateLayerAwardedCredits(prefix, type);
            stats[type].credit_total = total;

            totalAwardedCredits = (totalAwardedCredits + total);

            stats[type].percentage_selected_aim = this.calculatePercentageOfAim(
                prefix,
                type,
                principle,
                stats[type].credit_total
            );

            stats[type].percentage_total_credits = this.calculatePercentageOfAim(
                prefix,
                type,
                'full',
                stats[type].credit_total
            );

            let awarded = '';

            if(stats[type].credit_total >= requiredForRatings[type].basic) {
                awarded = 'basic';
            }
            if(stats[type].credit_total >= requiredForRatings[type].partial) {
                awarded = 'partial';
            }

            if(stats[type].credit_total >= requiredForRatings[type].full) {
                awarded = 'full';
            }

            stats[type].awarded_circularity_rating = awarded;

            totalAvailableCreditsFroAim = (totalAvailableCreditsFroAim + this.calc.calculateLayerAvailableCredits(
                prefix,
                type,
                principle
            ));

            totalAvailableCredits = (totalAvailableCredits + this.calc.calculateLayerAvailableCredits(
                prefix,
                type,
                'full'
            ));
        })

        stats.totals.credit_total = totalAwardedCredits;
        stats.totals.available_credits = totalAvailableCredits;
        stats.totals.available_credits_for_aim = totalAvailableCreditsFroAim;
        stats.totals.percentage_selected_aim = (totalAwardedCredits / totalAvailableCreditsFroAim) * 100;
        stats.totals.percentage_total_credits = (totalAwardedCredits / totalAvailableCredits) * 100;

        // let totalsRequired = {};
        // _.each(requiredForRatings, (items, type) => {
        //     _.each(items, (value, aim) => {
        //         if(totalsRequired[aim]) {
        //             totalsRequired[aim] = (totalsRequired[aim] + value);
        //             return;
        //         }
        //         totalsRequired[aim] = value;
        //     });
        // });
        let totalRating = null;
        let totalPercentage = stats.totals.percentage_total_credits;

        if(totalPercentage >= 55 ) {
            totalRating = 'basic';

        }
        if(totalPercentage >= 80) {
            totalRating = 'partial';
        }

        if(totalPercentage >= 100) {
            totalRating = 'full';
        }
        stats.totals.awarded_circularity_rating = totalRating;
    }

    designForAdaptability() {
        let stats = new CreditSummaryStats('Design For Adaptability');
        this.fillStatsForPrefix(DESIGN_FOR_ADAPTABILITY, DESIGN_FOR_ADAPTABILITY_PREFIX, stats);
        return stats;

    }

    designForDeconstruction() {
        let stats = new CreditSummaryStats('Design for Deconstruction');
        this.fillStatsForPrefix(DESIGN_FOR_DECONSTRUCTION, DESIGN_FOR_DECONSTRUCTION_PREFIX, stats);
        return stats;
    }

    circularMaterialSelection() {
        let stats = new CreditSummaryStats('Circular Material Selection');
        this.fillStatsForPrefix(CIRCULAR_MATERIAL_SELECTION, CIRCULAR_MATERIAL_SELECTION_PREFIX, stats);
        return stats;
    }

    resourceEfficiency() {
        let stats = new CreditSummaryStats('Resource Efficiency');
        this.fillStatsForPrefix(RESOURCE_EFFICIENCY, RESOURCE_EFFICIENCY_PREFIX, stats);
        return stats;
    }

    getAll() {
        return {
            design_for_adaptability : this.designForAdaptability(),
            design_for_deconstruction : this.designForDeconstruction(),
            circular_material_selection : this.circularMaterialSelection(),
            resource_efficiency : this.resourceEfficiency()
        }

    }

}
