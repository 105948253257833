import React, {Component} from 'react';
import ProgressBar from "./progress-bar";
import ProgressNumbers from "./progress-numbers";
import ReactDOM from 'react-dom';
import {percentageComplete} from "../lib/helpers/project-helpers";
import {TOTAL_STAGES} from "../containers/project-tool";

class ProjectProgress extends Component {


    render() {
        return (
            <div className={'project-progress'}>
                <div className="container d-flex justify-content-start align-items-center">
                    <ProgressBar complete={percentageComplete(this.props.project, TOTAL_STAGES)} />
                    <ProgressNumbers onClick={this.props.onClick} project={this.props.project} number={this.props.screen} />
                </div>
            </div>
        );
    }
}

ProjectProgress.defaultProps = {
    screen : 1,
    project: {},
    onClick : stage => console.log(stage)
}

export default ProjectProgress;

