export default class Collection {

    constructor(items) {
        this.items = items;
    }

    each(callback) {
        _.each(this.items, callback);
    }

    find(name = '') {
        return _.find(this.items, item => {
            return item.name === name;
        })
    }

    count() {
        return _.size(this.items);
    }

    add(item) {
        this.items.push(item);
    }

}
