import React, {Component} from 'react';
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import EditableTable from "../../../components/editable-table";
import BaseStatementScreen from "./base-statement-screen";
import {
    BILL_OF_MATERIALS_STATEMENT_SCREEN,
    ECONOMY_STATEMENT_QUESTIONS
} from "../../../lib/project/economy-statement-questions";


class BillOfMaterials extends BaseStatementScreen {

    constructor(props) {
        super(props);

        this.screen = BILL_OF_MATERIALS_STATEMENT_SCREEN;


    }


    render() {
        const qs = ECONOMY_STATEMENT_QUESTIONS[BILL_OF_MATERIALS_STATEMENT_SCREEN];

        return (
            <div className={'sub-screen'}>
                <div className={`card card-collapsable mb-5 ${this.isOpen('site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('site')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'bill-materials'} name={'site-info-link'} /> Site
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.site.cols}
                            fixed={qs.site.fixed}
                            values={this.getValues('site')}
                            onChange={(key, row, value) => {
                                this.handleChange('site', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('site', row)}

                        />
                    </div>
                </div>


                <div className={`card card-collapsable mb-5 ${this.isOpen('structure')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('structure')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'bill-materials'} name={'site-info-link'} /> Structure
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.structure.cols}
                            fixed={qs.structure.fixed}
                            values={this.getValues('structure')}
                            onChange={(key, row, value) => {
                                this.handleChange('structure', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('structure', row)}

                        />
                    </div>
                </div>


                <div className={`card card-collapsable mb-5 ${this.isOpen('skin')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('skin')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'bill-materials'} name={'skin-info-link'} /> Skin
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.skin.cols}
                            fixed={qs.skin.fixed}
                            values={this.getValues('skin')}
                            onChange={(key, row, value) => {
                                this.handleChange('skin', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('skin', row)}
                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('services')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('services')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'bill-materials'} name={'services-info-link'} /> Services
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.services.cols}
                            fixed={qs.services.fixed}
                            values={this.getValues('services')}
                            onChange={(key, row, value) => {
                                this.handleChange('services', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('services', row)}
                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('space')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('space')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'bill-materials'} name={'space-info-link'} /> Space
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.space.cols}
                            fixed={qs.space.fixed}
                            values={this.getValues('space')}
                            onChange={(key, row, value) => {
                                this.handleChange('space', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('space', row)}

                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BillOfMaterials;
