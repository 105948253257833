import React, {Component} from 'react';
import {NEW_BUILD_ZONES_SECTION, PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION} from "../project-details";
import ZoneResponse from "../../../components/zones/zone-response";
import ZoneQuestion from "../../../components/zones/zone-question";
import {CIRCULARITY_CRITERIA_STAGE} from "../circularity-criteria";
import {CreditsCalculator} from "../../../lib/project/credits-calculator";
import Lang from '../../../lib/lang/lang';
import {AIM_REQUIRED_QUESTIONS} from "../../../lib/project/aim-required-questions";

export const CARD_TITLE = 'Circularity Rating';

class BaseSubScreen extends Component {
    toggleOpen(screen) {
        if (this.state.open[screen]) {
            let open = this.state.open;
            delete open[screen];
            return this.setState({
                open: open
            });
        }

        return this.setState({
            open: Object.assign({}, this.state.open, {
                [screen]: true
            })
        })
    }

    isOpen(screen) {
        return this.state.open[screen] ? 'open' : '';
    }

    getBuildZones(type) {
        let section = this.props.project.getSection(PROJECT_DETAILS_STAGE, type);
        return section ? section.values : []
    }

    updateZone(zone, key, value) {
        zone.value[key] = value;
        this.props.project.updateValue(PROJECT_DETAILS_STAGE, zone.value.type, zone.key, zone.value);
    }


    renderZone(zone, questionKey, layer) {
        let calc = new CreditsCalculator(this.props.project);

        return (
            <ZoneResponse
                key={zone.key}
                zone={zone.value}
                credit_available={calc.calculateAvailableCredits(zone, layer, questionKey)}
                credits_awarded={calc.calculateCreditsAwarded(zone, layer, questionKey)}
                value={zone.value[questionKey] ? zone.value[questionKey] : ''}
                onChange={value => this.updateZone(zone, questionKey, value)}
                disabled={zone.value[layer] ? zone.value[layer] === 'no' : false}
            />
        );
    }

    renderBuildZones(questionKey, layer) {
        return (
            <React.Fragment>
                <div className="d-flex align-items-start flex-row">
                    {
                        this.getBuildZones(NEW_BUILD_ZONES_SECTION).map(zone => {
                            return this.renderZone(zone, questionKey, layer)
                        })
                    }

                </div>

                <div className="d-flex align-items-start flex-row">
                    {
                        this.getBuildZones(REFURB_BUILD_ZONES_SECTION).map(zone => {
                            return this.renderZone(zone, questionKey, layer)
                        })
                    }

                </div>
            </React.Fragment>
        );
    }

    handleQuestionChange(e, key) {
        this.props.project.updateValue(this.stage, key, e.target.name, e.target.value);
    }

    getQuestionValues(stage) {

        let params = {};
        let section = this.props.project.getSection(this.stage, stage);

        if (section) {
            section.values.forEach(item => {
                params[item.key] = item.value;
            });
        }

        return params;
    }

    allQuestionsAchieved(
        layer,
        questions = {basic: [], partial: [], full: []}
    ) {
        let achieved = true;
        let set = questions[this.getCurrentAim()];
        if (!set) {
            return false;
        }
        let calc = new CreditsCalculator(this.props.project);
        set.forEach(question => {
            if (calc.calculateQuestionTotalCredits(layer, `${this.prefix}${question}`) !== 1) {
                achieved = false;
            }
        });
        return achieved;
    }

    getCurrentAim() {
      return   this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', this.stage);
    }

    renderQuestion(layer, title, id, extraMeta=  []) {
        let questionID = `${this.prefix}${id}`;
        let calc = new CreditsCalculator(this.props.project);
        let section = Lang.getSection(this.contentSection)
        let reference = {};
        let required = !!_.find(AIM_REQUIRED_QUESTIONS[this.prefix][layer][this.getCurrentAim()], q => {
                    return q === id;
            });

        if(section) {
            let question = section[`q${id}`];

            if (question) {
                reference = {
                    reference : section[`q${id}-reference`] ? section[`q${id}-reference`] : '',
                    link : section[`q${id}-link`] ? section[`q${id}-link`] : '',
                    additionalInfo : section[`q${id}-additional-information`] ? section[`q${id}-additional-information`] : '',
                };
                title = question;
            }
        }

        return (
            <ZoneQuestion
                title={`${this.namedPrefix}${id}:`}
                question={title}
                references={[reference]}
                values={this.getQuestionValues(questionID)}
                creditsTotal={calc.calculateQuestionTotalCredits(layer, questionID)}
                onChange={e => this.handleQuestionChange(e, questionID)}
                onZoneInfoClick={this.props.onZoneInfoClick}
                required={required}
                extraMeta={extraMeta}
            >
                {
                    this.renderBuildZones(questionID, layer)
                }
            </ZoneQuestion>
        );
    }

}

export default BaseSubScreen;
