import React, {Component} from 'react';
import {CIRCULAR_ECONOMY_STATEMENT_STAGE} from "../circular-economy-statement";

class BaseStatementScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open : {

            }
        };
    }

    toggleOpen(screen) {
        if (this.state.open[screen]) {
            let open = this.state.open;
            delete open[screen];
            return this.setState({
                open : open
            });
        }

        return  this.setState({
            open : Object.assign({}, this.state.open, {
                [screen] : true
            })
        })
    }

    isOpen(screen) {
        return this.state.open[screen]  ? 'open' : '';
    }

    handleDeleteRow(section, row) {
        let currentValues =  this.getValues(section) || {};
        console.log(row, currentValues);

        if(currentValues[row]) {
            delete currentValues[row];
        }



        this.props.project.updateValue(
            CIRCULAR_ECONOMY_STATEMENT_STAGE,
            this.screen,
            section,
            currentValues
        );
    }

    getValues(section) {
        return this.props.project.getValue(
            CIRCULAR_ECONOMY_STATEMENT_STAGE,
            this.screen,
            section
        );
    }


    handleChange(section, row, key, value) {
        let p = this.props.project;
        let currentValues =  this.getValues(section) || {};

        p.updateValue(
            CIRCULAR_ECONOMY_STATEMENT_STAGE,
            this.screen,
            section,
            Object.assign({}, currentValues, {
                [row] : Object.assign({}, currentValues[row], {
                    [key] : value
                })
            })
        );

    }

}

export default BaseStatementScreen;
