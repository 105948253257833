import React, {Component} from 'react';
import InfoIcon from "../../components/info-icon";
import Section from "../../lib/lang/section";
import Button from "../../lib/lang/button";
import Content from "../../lib/lang/content";
import Lang from '../../lib/lang/lang';
import ReadMore from "../../components/read-more";

export const PROJECT_DETAILS_STAGE = 'project_details_stage';
export const DETAILS_SECTION = 'General Details';
export const NEW_BUILD_ZONES_SECTION = 'New Build Zones';
export const REFURB_BUILD_ZONES_SECTION = 'Refurbishment/Renovation Build Zones';


class BuildZone extends Component {

    handleUpdate(e) {

        this.props.onUpdate(Object.assign({}, this.props.zone, {
            [e.target.name]: e.target.value
        }));
    }

    getZoneValue(key) {
        return this.props.zone[key] ? this.props.zone[key] : '';
    }


    render() {
        return (
            <div className="card mb-5">
                <div className="card-header card-header--dark-green">
                    {this.getZoneValue('name')}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="">Name</label>
                            <input
                                type="text"
                                className={'form-control'}
                                name={'name'}
                                value={this.getZoneValue('name')}
                                onChange={this.handleUpdate.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Building Use</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('building_use')}
                                name={'building_use'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value="residential">Residential</option>
                                <option value="office">Office</option>
                                <option value="retail">Retail</option>
                                <option value="industrial">Industrial</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Structural Form</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('structural_form')}
                                name={'structural_form'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value="steel frame">Steel Frame</option>
                                <option value="concrete frame pre-cast">Concrete Frame (pre-cast)</option>
                                <option value="concrete frame in-situ">Concrete Frame (in-situ)</option>
                                <option value="glulam frame">Glulam Frame</option>
                                <option value="panelised clt">Panelised CLT</option>
                                <option value="cold-formed steel">Cold-formed Steel</option>
                                <option value="timber frame (other)">Timber Frame (other)</option>
                                <option value="modular (steel)">Modular (steel)</option>
                                <option value="modular (timber)">Modular (timber)</option>
                                <option value="modular (aluminium)">Modular (aluminium)</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Gross Floor Area (m2)</label>
                            <input type="number"
                                   className={'form-control'}
                                   name={'gross_floor_area_m2'}
                                   value={this.props.zone.gross_floor_area_m2 ? this.props.zone.gross_floor_area_m2 : 0}
                                   onChange={this.handleUpdate.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <h4 className={'dark-green-text'}>Building Layers Affected</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Site</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('site')}
                                name={'site'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </div>
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Structure</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('structure')}
                                name={'structure'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </div>
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Skin</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('skin')}
                                name={'skin'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Services</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('services')}
                                name={'services'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </div>
                        <div className="form-group form-group--reverse col-4">
                            <label htmlFor="">Space</label>
                            <select
                                onChange={this.handleUpdate.bind(this)}
                                value={this.getZoneValue('space')}
                                name={'space'}
                                className="form-control"
                            >
                                <option value={""}>Select Option</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="">Description</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-group">
                            <textarea
                                className={'form-control'}
                                name={'description'}
                                value={this.props.zone.description ? this.props.zone.description : ''}
                                onChange={this.handleUpdate.bind(this)}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ProjectDetails extends Component {


    getNumberOfBuildZones(type) {
        let section = this.props.project.getSection(PROJECT_DETAILS_STAGE, type);
        return section ? section.values.length : 0;
    }


    getDefaultBuildingZoneValues(type) {

        const value = type === NEW_BUILD_ZONES_SECTION ? 'yes' : 'no';

        return {
            'site' : value,
            'structure' : value,
            'skin' : value,
            'services' : value,
            'space' : value
        };

    }

    changeBuildZones(type, requiredNumber) {

        if (requiredNumber > 3) {
            return;
        }

        let section = this.props.project.getSection(PROJECT_DETAILS_STAGE, type);
        if (requiredNumber > section.values.length) {
            section.values.push({
                key: requiredNumber, value: Object.assign({}, {
                    'name': type === NEW_BUILD_ZONES_SECTION ? `New Build: Zone ${requiredNumber}` : `Refurbishment / Renovation: Zone ${requiredNumber}`
                }, this.getDefaultBuildingZoneValues(type))
            });
            this.props.project.stages.find(PROJECT_DETAILS_STAGE).complete = false;
            return this.props.project.fireUpdateEvent();
        }

        if (requiredNumber < section.values.length) {
            section.values = section.values.splice(0, requiredNumber);
            this.props.project.stages.find(PROJECT_DETAILS_STAGE).complete = false;
            return this.props.project.fireUpdateEvent();
        }
    }

    handleZoneChange(type, key, zone) {

        this.props.project.updateValue(
            PROJECT_DETAILS_STAGE,
            type,
            key,
            zone
        );
    }

    getAlertText() {

        let section = Lang.getSection('project-details');

        if(section === null) {
            return '';
        }

        switch (this.props.project.getValue(
            PROJECT_DETAILS_STAGE,
            DETAILS_SECTION,
            '2'
        )) {
            case 'none of the above' :
                return section['no-plan']  ? section['no-plan'] : '';
            default :
                return section['london-plan']  ? section['london-plan'] : '';

        }
    }

    isComplete() {

        if (this.getNumberOfBuildZones(NEW_BUILD_ZONES_SECTION) === 0 && this.getNumberOfBuildZones(REFURB_BUILD_ZONES_SECTION) === 0) {
            return false;
        }

        if (!this.props.project.getValue(PROJECT_DETAILS_STAGE, DETAILS_SECTION, '2')) {
            return false;
        }

        let newBuild = this.props.project.getSection(PROJECT_DETAILS_STAGE, NEW_BUILD_ZONES_SECTION);

        if (newBuild) {
            for (let i = 0; i < newBuild.values.length; i++) {
                if (!this.buildComplete(newBuild.values[i])) {
                    return false;
                }
            }
        }

        let refurb = this.props.project.getSection(PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION);

        if (refurb) {
            for (let i = 0; i < refurb.values.length; i++) {
                if (!this.buildComplete(refurb.values[i])) {
                    return false;
                }
            }
        }


        return true;
    }

    buildComplete(build) {
        let requiredTextFields = ['name', 'building_use', 'services', 'site', 'skin', 'space', 'structural_form', 'structure'];
        for (let i = 0; i < requiredTextFields.length; i++) {

            if (!build.value[requiredTextFields[i]]) {
                return false;
            }
        }


        if (!build.value.gross_floor_area_m2) {
            return false;
        }

        if (parseInt(build.value.gross_floor_area_m2) === 0) {
            return false;
        }

        return true;
    }

    render() {
        let developmentType = this.props.project.getValue(
            PROJECT_DETAILS_STAGE,
            DETAILS_SECTION,
            "1"
        );
        return (
            <div className={'project-details'}>
                <div className={'screen'}>
                    <div className="container">
                        <div className="row screen__header">
                            <div className="col-5">
                                <h1 className="screen__title">
                                    Project Details
                                </h1>
                                <Section name={'project-details'}>
                                    <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                                </Section>
                            </div>
                            <div className="col-7">
                                <ReadMore section={'project-details'} content={'stage-description'}>

                                </ReadMore>

                            </div>
                        </div>
                        <div className="screen__body">
                            <div className="card mb-5">
                                <div className="card-header card-header--dark-green">
                                     General Details
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <label>Development type</label>
                                                <select
                                                    onChange={e => {
                                                        let val = e.target.value

                                                        this.props.project.updateValue(
                                                            PROJECT_DETAILS_STAGE,
                                                            DETAILS_SECTION,
                                                            '1',
                                                            val
                                                        );

                                                        if (val === 'refurb-renovation') {
                                                            return this.changeBuildZones(NEW_BUILD_ZONES_SECTION, 0);
                                                        }
                                                        if (val === 'new') {
                                                            return this.changeBuildZones(REFURB_BUILD_ZONES_SECTION, 0);
                                                        }

                                                    }}
                                                    value={developmentType}
                                                    className="form-control"
                                                >
                                                    <option value="mixed">Mixed</option>
                                                    <option value="new">New Build</option>
                                                    <option value="refurb-renovation">Refurbishment / Renovation
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <label>Number of New Build Zones</label>
                                                <input value={this.getNumberOfBuildZones(NEW_BUILD_ZONES_SECTION)}
                                                       onKeyPress={e => e.preventDefault()}
                                                       disabled={developmentType === 'refurb-renovation'}
                                                       onChange={e => {
                                                           this.changeBuildZones(NEW_BUILD_ZONES_SECTION, e.target.value);
                                                       }}
                                                       className={'form-control'} type="number" min={0} max={3}/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <label>Refurbishment / Renovation Build Zones</label>
                                                <input onKeyPress={e => e.preventDefault()}
                                                       value={this.getNumberOfBuildZones(REFURB_BUILD_ZONES_SECTION)}
                                                       disabled={developmentType === 'new'}

                                                       onChange={e => {
                                                           this.changeBuildZones(REFURB_BUILD_ZONES_SECTION, e.target.value);
                                                       }}
                                                       className={'form-control'} type="number" min={0} max={3}/>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <label>Project Referability</label>
                                                <select
                                                    value={this.props.project.getValue(PROJECT_DETAILS_STAGE, DETAILS_SECTION, '2')}
                                                    onChange={e => this.props.project.updateValue(PROJECT_DETAILS_STAGE, DETAILS_SECTION, '2', e.target.value)}
                                                    className="form-control">
                                                    <option value={""}>Select Option</option>
                                                    <option value="150 residential units or more">Comprising 150
                                                        residential units or more
                                                    </option>
                                                    <option value="over 100,000 m2 (in the city)">Over 100,000 m2 (in
                                                        the city)
                                                    </option>
                                                    <option value="over 20,000 m2 (central)">Over 20,000 m2 (central)
                                                    </option>
                                                    <option value="over 15,000 m2 (outer)">Over 15,000 m2 (outer)
                                                    </option>
                                                    <option
                                                        value="over 25 meters in height within thames policy area">Over
                                                        25 meters in height within Thames Policy Area
                                                    </option>
                                                    <option
                                                        value="over 150m height elsewhere in the city of london">Over
                                                        150m height elsewhere in the city orf London
                                                    </option>
                                                    <option value="30 meter in height elsewhere in london">30 meter in
                                                        height elsewhere in London
                                                    </option>
                                                    <option
                                                        value="of existing buildings which will increase their height by more than 15m">Of
                                                        existing buildings which will increase their height by more than
                                                        15m
                                                    </option>
                                                    <option value="on green belt or metropolitan open land">On green
                                                        Belt or Metropolitan Open Land
                                                    </option>
                                                    <option value="none of the above">None of the above</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.project.getValue(PROJECT_DETAILS_STAGE, DETAILS_SECTION, '2') &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert-info">
                                                    {
                                                        this.getAlertText()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            {
                                this.props.project.getSection(PROJECT_DETAILS_STAGE, NEW_BUILD_ZONES_SECTION) &&
                                this.props.project.getSection(PROJECT_DETAILS_STAGE, NEW_BUILD_ZONES_SECTION).values.map(value => {
                                    return (
                                        <BuildZone
                                            key={value.key}
                                            zone={value.value}
                                            onUpdate={zone => {
                                                this.handleZoneChange(NEW_BUILD_ZONES_SECTION, value.key, zone);
                                            }}
                                        />
                                    );
                                })
                            }
                            {
                                this.props.project.getSection(PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION) &&
                                this.props.project.getSection(PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION).values.map(value => {
                                    return (
                                        <BuildZone
                                            key={value.key}
                                            zone={value.value}
                                            onUpdate={zone => {
                                                this.handleZoneChange(REFURB_BUILD_ZONES_SECTION, value.key, zone);
                                            }}
                                        />
                                    );
                                })
                            }


                        </div>

                        <div className="screen__footer d-flex">
                            <button onClick={() => this.props.onBack(this.props.project)}
                                    className="btn btn--ex-large btn--purple mr-auto">Save
                                and return
                            </button>
                            <button disabled={!this.isComplete()} onClick={() => {
                                this.props.project.stageComplete(PROJECT_DETAILS_STAGE);
                                this.props.onNext(this.props.project)
                            }} className="btn btn--ex-large ml-auto">Save and proceed
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectDetails;
