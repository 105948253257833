import React, {Component} from 'react';
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import CardStatus from "../../../components/card-status";
import BaseSubScreen, {CARD_TITLE} from "./base-sub-screen";
import {AIM_REQUIRED_QUESTIONS, RESOURCE_EFFICIENCY_PREFIX} from "../../../lib/project/aim-required-questions";

export const RESOURCE_EFFICIENCY = 'resource_efficiency';

class ResourceEfficiency extends BaseSubScreen {

    constructor(props) {
        super(props);
        this.state = {
            open: {}
        };

        this.stage = RESOURCE_EFFICIENCY;
        this.prefix = RESOURCE_EFFICIENCY_PREFIX;
        this.namedPrefix = 'RE'
        this.contentSection = 'resource-efficiency';

    }

    render() {
        let q = AIM_REQUIRED_QUESTIONS[RESOURCE_EFFICIENCY_PREFIX];
        return (
            <div className={'sub-screen'}>

                <div className={`card card-collapsable mb-5 ${this.isOpen('site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('site')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'site-info-link'}/> Site <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('site', q.site)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'site',
                            'Waste\' material from on-site buildings or external sources used at its highest value.',
                            1,
                            [
                                {
                                    label : 'Percentage of material reused from existing on-site buildings or external sources\n',
                                    name : 'percentage'
                                }
                            ]
                        )
                    }

                    {
                        this.renderQuestion(
                            'site',
                            'Cut and fill balance achieved\n',
                            2
                        )
                    }
                    {
                        this.renderQuestion(
                            'site',
                            'Temporary works are reusable\n',
                            3
                        )
                    }
                    {
                        this.renderQuestion(
                            'site',
                            'Greywater recycling system incorporated \n',
                            4
                        )
                    }

                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('structure')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('structure')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'structure-info-link'}/> Structure <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('structure', q.structure)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'structure',
                            'Waste material from on-site buildings or external sources used at its highest value.',
                            5,
                            [
                                {
                                    label : 'Percentage of material reused from existing on-site buildings or external sources\n',
                                    name : 'percentage'
                                }
                            ]
                        )
                    }

                    {
                        this.renderQuestion(
                            'structure',
                            'Considering design requirements (e.g. adaptability criteria), material use is optimised\n',
                            6
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Where composite materials/products are used, are these easily separated into component parts for future recycling? (if no composites are used, respond \'Yes\')',
                            7
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Design coordinated to avoid excess cutting and jointing of materials / components that generate waste',
                            8
                        )
                    }
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('skin')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('skin')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'skin-info-link'}/> Skin <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('skin', q.skin)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'skin',
                            'Waste material from on-site envelope or external sources used at its highest value.',
                            9,
                            [
                                {
                                    label : 'Percentage of material reused from existing on-site buildings or external sources\n',
                                    name : 'percentage'
                                }
                            ]
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Where composite materials/products are used, are these easily separated into component parts for future recycling? (if no composites are used, respond \'Yes\')',
                            10
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Design coordinated to avoid excess cutting and jointing of materials / components',
                            11
                        )
                    }
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('services')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('services')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'services-info-link'}/> Services <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('services', q.services)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'services',
                            'Waste material from on-site envelope or external sources used at its highest value.',
                            12,
                            [
                                {
                                    label : 'Percentage of material reused from existing on-site buildings or external sources\n',
                                    name : 'percentage'
                                }
                            ]
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Where composite materials/products are used, are these easily separated into component parts for future recycling? (if no composites are used, respond \'Yes\')',
                            13
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Design coordinated to avoid excess cutting and jointing of materials / components \n',
                            14
                        )
                    }

                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('space')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('space')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'space-info-link'}/> Space <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('space', q.space)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'space',
                            'Waste material from on-site envelope or external sources used at its highest value.',
                            15,
                            [
                                {
                                    label : 'Percentage of material reused from existing on-site buildings or external sources\n',
                                    name : 'percentage'
                                }
                            ]
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Where composite materials/products are used, are these easily separated into component parts for future recycling? (if no composites are used, respond \'Yes\')',
                            16
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Design coordinated to avoid excess cutting and jointing of materials / components \n',
                            17
                        )
                    }
                </div>

            </div>
        );
    }
}

export default ResourceEfficiency;
