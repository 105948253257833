import React, {Component} from 'react';
import {RIBA_STAGE} from "../containers/screens/riba-one";
import {STRATEGIC_STAGE} from "../containers/screens/strategic";
import {PROJECT_DETAILS_STAGE} from "../containers/screens/project-details";
import {CIRCULARITY_CRITERIA_STAGE} from "../containers/screens/circularity-criteria";
import {CIRCULAR_ECONOMY_STATEMENT_STAGE} from "../containers/screens/circular-economy-statement";
import {CIRCULARITY_CRITERIA_OVERVIEW_STAGE} from "../containers/screens/circularity-criteria-overview";
import {SCREE_STAGES} from "../containers/project-tool";

class ProgressNumbers extends Component {


    previousStages(current) {
        let stages = [SCREE_STAGES[current]];
        let i = current - 1;
        while(i >= 1) {
            stages.push(SCREE_STAGES[i]);
            i = i -1;
        }
        return stages;
    }

    status(screen) {

        let c = this.props.number;
        let p = this.props.project;

        if(c === screen) {
            return 'active';
        }

        return p.areStagesComplete(this.previousStages(screen)) ? 'complete' : '';


        // if (screen === 1) {
        //     if (c === 2) {
        //         return 'active';
        //     }
        //
        //     if (p.isStageComplete(RIBA_STAGE) && p.isStageComplete(STRATEGIC_STAGE)) {
        //         return 'complete';
        //     }
        //
        //     return '';
        // }
        //
        // if (screen === 2) {
        //     if (c === 3) {
        //         return 'active';
        //     }
        //
        //     if (p.isStageComplete(PROJECT_DETAILS_STAGE)) {
        //         return 'complete';
        //     }
        //     return '';
        // }
        //
        // if (screen === 3) {
        //     if (c === 4) {
        //         return 'active';
        //     }
        //     if (p.isStageComplete(CIRCULARITY_CRITERIA_STAGE)) {
        //         return 'complete';
        //     }
        //     return '';
        // }
        //
        // if (screen === 4) {
        //     if (c === 5) {
        //         return 'active';
        //     }
        //     if (p.isStageComplete(CIRCULARITY_CRITERIA_OVERVIEW_STAGE)) {
        //         return 'complete';
        //     }
        //     return '';
        // }
        //
        // if (screen === 5) {
        //     if (c === 6) {
        //         return 'active';
        //     }
        //     if (p.isStageComplete(CIRCULAR_ECONOMY_STATEMENT_STAGE)) {
        //         return 'complete';
        //     }
        //     return '';
        // }
        //
        //
        // return '';
    }

    render() {
        return (
            <div className={'progress-numbers'}>
                <div onClick={() => this.props.onClick(1)} className={`progress-numbers__number ${this.status(1)}`}>1</div>
                <div className="progress-numbers__divider"></div>

                <div onClick={() => this.props.onClick(2)} className={`progress-numbers__number ${this.status(2)}`}>2</div>
                <div className="progress-numbers__divider"></div>

                <div onClick={() => this.props.onClick(3)} className={`progress-numbers__number ${this.status(3)}`}>3</div>
                <div className="progress-numbers__divider"></div>

                <div onClick={() => this.props.onClick(4)} className={`progress-numbers__number ${this.status(4)}`}>4</div>
                <div className="progress-numbers__divider"></div>

                <div onClick={() => this.props.onClick(5)} className={`progress-numbers__number ${this.status(5)}`}>5</div>

            </div>
        );
    }
}

ProgressNumbers.defaultProps = {
    project: {},
    onClick : stage => console.log(stage)
};


export default ProgressNumbers;
