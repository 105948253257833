import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Pie} from 'react-chartjs-2';
import {CreditSummaryStatsFactory} from "../lib/project/credit-summary-stats-factory";
import {DEFAULT_CHART_COLORS, DEFAULT_PIE_CHART_SETTINGS} from "../lib/project/default-pie-settings";
import colors from "../lib/helpers/colors";
import Project from "../lib/project/project";

const DEFAULT_PIE_CHAR_SETTING = DEFAULT_PIE_CHART_SETTINGS;

const LABEL_COLORS = {
    "design for adaptability": colors[1],
    "design for deconstruction" : colors[2],
    "circular material selection" : colors[3],
    "resource efficiency" : colors[4]
};


class CreditsPrincipleGraph extends Component {



    totalCreditProps() {

        let factory = new CreditSummaryStatsFactory(this.props.project);
        let allStats = factory.getAll();
        let totalCredits = 0;
        let totalAwardedCredits = 0;
        _.each(allStats, (stats) => {
            totalCredits = (totalCredits + stats.totals.available_credits);
            totalAwardedCredits = (totalAwardedCredits + stats.totals.credit_total);
        });

        let data = [(totalCredits - totalAwardedCredits)];
        let labels = ['Remaining Credits'];
        _.each(allStats, stat => {
            data.push(stat.totals.credit_total);
            labels.push(stat.name)
        })

        let props = {
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor : labels.map(label => this.getColor(label))
                    }
                ]
            },
            options: Object.assign({}, DEFAULT_PIE_CHART_SETTINGS.options, {
                legend: {
                    display : true,
                    labels: {
                        fontSize: this.props.large === true ? 20 : 12
                    }
                }
            })
        };

        return Object.assign({}, DEFAULT_PIE_CHAR_SETTING, props);

    }

    getColor(label) {
        let c = LABEL_COLORS[label.toLowerCase()];

        return c ? c : '#ffffff';

    }

    getAchievedProps() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        let allStats = factory.getAll();
        let data = [];
        let labels = [];
        _.each(allStats, stat => {
            data.push(stat.totals.credit_total);
            labels.push(stat.name)
        });

        let props =  {
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor : labels.map(label => this.getColor(label))
                    }
                ]
            },
            options: Object.assign({}, DEFAULT_PIE_CHART_SETTINGS.options, {
                legend: {
                    display : true,
                    labels: {
                        fontSize: this.props.large === true ? 20 : 12
                    }
                }
            })
        };


        return Object.assign({}, DEFAULT_PIE_CHAR_SETTING, props);

    }


    render() {
        return (
            <div className={'credits-principle-graph'}>
                <div className="row">
                    <div className="col-12 col-md-6 p-5">
                        <Pie {...this.totalCreditProps()} />
                    </div>
                    <div className="col-12 col-md-6 p-5">
                        <Pie {...this.getAchievedProps()} />
                    </div>
                </div>
            </div>
        );
    }
}

CreditsPrincipleGraph.defaultProps = {
    project: {},
    showLegend : false,
    large : false
};

export default CreditsPrincipleGraph;


let el = document.getElementById('credits-principle-graph');

if(el) {
    ReactDOM.render(
        <CreditsPrincipleGraph
            project={Project.make(JSON.parse(el.getAttribute('data-project')))}
            showLegend={true}
            large={$(el).hasClass('large-format')}
        />,
        el
    );
}
