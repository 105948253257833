import React, {Component} from 'react';
import BaseStatementScreen from "./base-statement-screen";
import {ECONOMY_STATEMENT_QUESTIONS, GLA1_STATEMENT_SCREEN} from "../../../lib/project/economy-statement-questions";
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import EditableTable from "../../../components/editable-table";

class GlaOne extends BaseStatementScreen {

    constructor(props) {
        super(props);
        this.screen = GLA1_STATEMENT_SCREEN;
    }


    render() {
        const qs = ECONOMY_STATEMENT_QUESTIONS[GLA1_STATEMENT_SCREEN];
        return (
            <div className={'sub-screen'}>
                <div className={`card card-collapsable mb-5 ${this.isOpen('new_development')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('new_development')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-one'} name={'new-development-info-link'} /> New Development
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.new_development.cols}
                            fixed={qs.new_development.fixed}
                            rows={qs.new_development.rows}
                            values={this.getValues('new_development')}
                            onChange={(key, row, value) => {
                                this.handleChange('new_development', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('new_development', row)}

                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('existing_site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('existing_site')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-one'} name={'existing-site-info-link'} /> Existing Site
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.existing_site.cols}
                            fixed={qs.existing_site.fixed}
                            rows={qs.existing_site.rows}
                            values={this.getValues('existing_site')}
                            onChange={(key, row, value) => {
                                this.handleChange('existing_site', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('existing_site', row)}

                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('municipal_waste')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('municipal_waste')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-one'} name={'municipal-waste-during-operation-info-link'} /> Municipal Waste During Operation
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.municipal_waste.cols}
                            fixed={qs.municipal_waste.fixed}
                            rows={qs.municipal_waste.rows}
                            values={this.getValues('municipal_waste')}
                            onChange={(key, row, value) => {
                                this.handleChange('municipal_waste', row, key, value);
                            }}
                            onDeleteRow={row => this.handleDeleteRow('municipal_waste', row)}

                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default GlaOne;
