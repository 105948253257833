export class CreditSummaryStats {

    constructor(name = '') {
        this.name = name;
        this.site = {};
        this.structure = {};
        this.skin = {};
        this.services = {};
        this.space = {};
        this.totals = {
            credit_total : 0,
            percentage_selected_aim : 0,
            percentage_total_credits : 0,
            awarded_circularity_rating: ''
        }
    }

    setStat(
        type,
        total,
        perCreditAim,
        perTotalCredits,
        awardedRating
    ) {
        this[type] = {
            credit_total : total,
            percentage_selected_aim : perCreditAim,
            percentage_total_credits : perTotalCredits,
            awarded_circularity_rating : awardedRating
        }
    }

}
