export const BILL_OF_MATERIALS_STATEMENT_SCREEN = 'bill_of_materials_statement_screen';
export const RECYCLING_WASTE_STATEMENT_SCREEN = 'recycling_waste_statement_screen';
export const GLA1_STATEMENT_SCREEN = 'ga1_statement_screen';
export const GLA2_STATEMENT_SCREEN = 'ga2_statement_screen';


export const ECONOMY_STATEMENT_QUESTIONS = {
    [GLA2_STATEMENT_SCREEN] : {
        section_a : {
            fixed : true,
            cols : [
                {
                    label: 'Site',
                    key: 'site'
                },
                {
                    label: 'Substructure',
                    key: 'substructure'
                },
                {
                    label: 'Superstructure',
                    key: 'superstructure'
                },
                {
                    label: 'Envelope',
                    key: 'envelope'
                },
                {
                    label: 'Services',
                    key: 'services'
                },
                {
                    label: 'Space',
                    key: 'space'
                },
                {
                    label: 'Stuff',
                    key: 'stuff'
                },
                {
                    label: 'Construction Stuff',
                    key: 'Construction_stuff'
                },
                {
                    label: 'Summary',
                    key: 'summary',
                    highlighted : true
                },
                {
                    label: 'Challenges',
                    key: 'challenges',
                    highlighted : true
                },
                {
                    label: 'Counter-Actions & Who, When',
                    key: 'counter_actions',
                    highlighted : true

                },
                {
                    label: 'Plan to Prove and Quantify',
                    key: 'plan_prove_quantity',
                    highlighted : true
                }
            ],
            rows : [
                {
                    label: 'A1: Minimising the quantities of materials used',
                    key: 'a1'
                },
                {
                    label: 'A2: Minimising the quantities of other resources used (energy, water, land)',
                    key: 'a2'
                },
                {
                    label: 'A3: Specifying and sourcing materials responsibly and sustainably ',
                    key: 'a3'
                }
            ]
        },
        section_b : {
            fixed : true,
            cols : [
                {
                    label: 'Site',
                    key: 'site'
                },
                {
                    label: 'Substructure',
                    key: 'substructure'
                },
                {
                    label: 'Superstructure',
                    key: 'superstructure'
                },
                {
                    label: 'Envelope',
                    key: 'envelope'
                },
                {
                    label: 'Services',
                    key: 'services'
                },
                {
                    label: 'Space',
                    key: 'space'
                },
                {
                    label: 'Stuff',
                    key: 'stuff'
                },
                {
                    label: 'Construction Stuff',
                    key: 'Construction_stuff'
                },
                {
                    label: 'Summary',
                    key: 'summary',
                    highlighted : true
                },
                {
                    label: 'Challenges',
                    key: 'challenges',
                    highlighted : true
                },
                {
                    label: 'Counter-Actions & Who, When',
                    key: 'counter_actions',
                    highlighted : true

                },
                {
                    label: 'Plan to Prove and Quantify',
                    key: 'plan_prove_quantity',
                    highlighted : true
                }
            ],
            rows : [
                {
                    label: 'B1: Design for reusability / recoverability / longevity / adaptability / flexibility',
                    key: 'b1'
                },
                {
                    label: 'B2: Design out construction, demolition, excavation and municipal waste arising',
                    key: 'b2'
                },
            ]
        },
        section_c : {
            fixed : true,
            cols : [
                {
                    label: 'Site',
                    key: 'site'
                },
                {
                    label: 'Substructure',
                    key: 'substructure'
                },
                {
                    label: 'Superstructure',
                    key: 'superstructure'
                },
                {
                    label: 'Envelope',
                    key: 'envelope'
                },
                {
                    label: 'Services',
                    key: 'services'
                },
                {
                    label: 'Space',
                    key: 'space'
                },
                {
                    label: 'Stuff',
                    key: 'stuff'
                },
                {
                    label: 'Construction Stuff',
                    key: 'Construction_stuff'
                },
                {
                    label: 'Summary',
                    key: 'summary',
                    highlighted : true
                },
                {
                    label: 'Challenges',
                    key: 'challenges',
                    highlighted : true
                },
                {
                    label: 'Counter-Actions & Who, When',
                    key: 'counter_actions',
                    highlighted : true

                },
                {
                    label: 'Plan to Prove and Quantify',
                    key: 'plan_prove_quantity',
                    highlighted : true
                }
            ],
            rows : [
                {
                    label: 'C1: Demolition waste (how waste from demolition of the layers will be managed)',
                    key: 'c1'
                },
                {
                    label: 'C2: Excavation waste (how waste from excavation will be managed)',
                    key: 'c2'
                },
                {
                    label: 'C3: Construction waste (how waste arising from construction of the layers will be reused or recycled)',
                    key: 'c3'
                },
                {
                    label: 'C4: Municipal waste (how the building will be designed to support operational waste management)',
                    key: 'c4'
                },
            ]
        }
    },
    [GLA1_STATEMENT_SCREEN] : {
        municipal_waste : {
            fixed: false,
            cols:  [
                {
                    label: 'Phase / Building / Area',
                    key: 'phase_building_area'
                },
                {
                    label: 'Steering Approach',
                    key: 'steering_approach'
                },
                {
                    label: 'Explanation',
                    key: 'explanation'
                },
                {
                    label: 'Supporting Analysis / Studies / Surveys / Audits',
                    key: 'supporting'
                },
            ]
        },
        existing_site : {
            fixed: false,
            cols:  [
                {
                    label: 'Phase / Building / Area',
                    key: 'phase_building_area'
                },
                {
                    label: 'Steering Approach',
                    key: 'steering_approach'
                },
                {
                    label: 'Explanation',
                    key: 'explanation'
                },
                {
                    label: 'Supporting Analysis / Studies / Surveys / Audits',
                    key: 'supporting'
                },
            ]
        },
        new_development : {
            fixed: false,
            cols:  [
                {
                    label: 'Phase / Building / Area',
                    key: 'phase_building_area'
                },
                {
                    label: 'Steering Approach',
                    key: 'steering_approach'
                },
                {
                    label: 'Explanation',
                    key: 'explanation'
                },
                {
                    label: 'Supporting Analysis / Studies / Surveys / Audits',
                    key: 'supporting'
                },
            ]
        }
    },
    [RECYCLING_WASTE_STATEMENT_SCREEN]: {
        municipal_waste: {
            fixed: true,
            cols: [
                {
                    label: 't/annum',
                    key: 't_annum'
                },
                {
                    label: 't/annum/person',
                    key: 't_annum_person'
                },
                {
                    label: '% reused offsite',
                    key: 'reused_offsite'
                },
                {
                    label: '% recycled offsite',
                    key: 'recycled_offsite'
                },
                {
                    label: '% composted onsite',
                    key: 'composted_onsite'
                },
                {
                    label: '% to landfill/not reused or recycled % composted onsite',
                    key: 'not_reused'
                }
            ],
            rows: [
                {
                    label: 'New Build',
                    key: 'new_build'
                },
                {
                    label: 'Existing Building',
                    key: 'existing_building'
                }
            ]
        },
        construction_waste_new: {
            fixed: true,
            cols: [
                {
                    label: 't/m2 GIA or NIA t/£100k value',
                    key: 'gia_nia'
                },
                {
                    label: '% reused onsite',
                    key: 'reused_onsite'
                },
                {
                    label: '% recycled onsite',
                    key: 'recycled_onsite'
                },
                {
                    label: '% reused offsite',
                    key: 'reused_offsite'
                },
                {
                    label: '% recycled offsite',
                    key: 'recycled_offsite'
                },
                {
                    label: '% to landfill/not reused or recycled',
                    key: 'not_reused'
                }
            ],
            rows: [
                {
                    label: 'Excavation',
                    key: 'excavation'
                },
                {
                    label: 'Demolition',
                    key: 'demolition'
                },
                {
                    label: 'Construction',
                    key: 'construction'
                }
            ]
        },
        construction_waste_existing: {
            fixed: true,
            cols: [
                {
                    label: 't/m2 GIA or NIA t/£100k value',
                    key: 'gia_nia'
                },
                {
                    label: '% reused onsite',
                    key: 'reused_onsite'
                },
                {
                    label: '% recycled onsite',
                    key: 'recycled_onsite'
                },
                {
                    label: '% reused offsite',
                    key: 'reused_offsite'
                },
                {
                    label: '% recycled offsite',
                    key: 'recycled_offsite'
                },
                {
                    label: '% to landfill/not reused or recycled',
                    key: 'not_reused'
                }
            ],
            rows: [
                {
                    label: 'Excavation',
                    key: 'excavation'
                },
                {
                    label: 'Demolition',
                    key: 'demolition'
                },
                {
                    label: 'Construction',
                    key: 'construction'
                }
            ]
        }
    },
    [BILL_OF_MATERIALS_STATEMENT_SCREEN]: {
        site: {
            fixed: false,
            cols: [
                {
                    label: 'Element',
                    key: 'element'
                },
                {
                    label: 'Material',
                    key: 'material'
                },
                {
                    label: 'Material quantity (kg)',
                    key: 'material_quantity'
                },
                {
                    label: 'Material intensity (kg/m2 GIA or NIA)',
                    key: 'material_intensity'
                },
                {
                    label: 'Recycled content (% by value)',
                    key: 'recycled_content'
                },
                {
                    label: 'Corresponding kgCO2e (optional)',
                    key: 'corresponding_kg_co22e'
                },
                {
                    label: 'Corresponding kgCO2e/m2 (optional)',
                    key: 'corresponding_kg_co22e_m2'
                },
                {
                    label: 'Reused content (% by value) (optional)',
                    key: 'reused_content'
                },
                {
                    label: 'Estimated reusable materials (kg/m2) (optional)',
                    key: 'estimate_reusable_materials'
                },
                {
                    label: 'Estimated recyclable materials (kg/m2) (optional)',
                    key: 'estimated_recyclable_materials'
                },
                {
                    label: 'Additional information',
                    key: 'additional_information'
                }
            ],
            rows: []
        },
        structure: {
            fixed: false,
            cols: [
                {
                    label: 'Element',
                    key: 'element'
                },
                {
                    label: 'Material',
                    key: 'material'
                },
                {
                    label: 'Material quantity (kg)',
                    key: 'material_quantity'
                },
                {
                    label: 'Material intensity (kg/m2 GIA or NIA)',
                    key: 'material_intensity'
                },
                {
                    label: 'Recycled content (% by value)',
                    key: 'recycled_content'
                },
                {
                    label: 'Corresponding kgCO2e (optional)',
                    key: 'corresponding_kg_co22e'
                },
                {
                    label: 'Corresponding kgCO2e/m2 (optional)',
                    key: 'corresponding_kg_co22e_m2'
                },
                {
                    label: 'Reused content (% by value) (optional)',
                    key: 'reused_content'
                },
                {
                    label: 'Estimated reusable materials (kg/m2) (optional)',
                    key: 'estimate_reusable_materials'
                },
                {
                    label: 'Estimated recyclable materials (kg/m2) (optional)',
                    key: 'estimated_recyclable_materials'
                },
                {
                    label: 'Additional information',
                    key: 'additional_information'
                }
            ],
            rows: []
        },
        skin: {
            fixed: false,
            cols: [
                {
                    label: 'Element',
                    key: 'element'
                },
                {
                    label: 'Material',
                    key: 'material'
                },
                {
                    label: 'Material quantity (kg)',
                    key: 'material_quantity'
                },
                {
                    label: 'Material intensity (kg/m2 GIA or NIA)',
                    key: 'material_intensity'
                },
                {
                    label: 'Recycled content (% by value)',
                    key: 'recycled_content'
                },
                {
                    label: 'Corresponding kgCO2e (optional)',
                    key: 'corresponding_kg_co22e'
                },
                {
                    label: 'Corresponding kgCO2e/m2 (optional)',
                    key: 'corresponding_kg_co22e_m2'
                },
                {
                    label: 'Reused content (% by value) (optional)',
                    key: 'reused_content'
                },
                {
                    label: 'Estimated reusable materials (kg/m2) (optional)',
                    key: 'estimate_reusable_materials'
                },
                {
                    label: 'Estimated recyclable materials (kg/m2) (optional)',
                    key: 'estimated_recyclable_materials'
                },
                {
                    label: 'Additional information',
                    key: 'additional_information'
                }
            ],
            rows: []
        },
        services: {
            fixed: false,
            cols: [
                {
                    label: 'Element',
                    key: 'element'
                },
                {
                    label: 'Material',
                    key: 'material'
                },
                {
                    label: 'Material quantity (kg)',
                    key: 'material_quantity'
                },
                {
                    label: 'Material intensity (kg/m2 GIA or NIA)',
                    key: 'material_intensity'
                },
                {
                    label: 'Recycled content (% by value)',
                    key: 'recycled_content'
                },
                {
                    label: 'Corresponding kgCO2e (optional)',
                    key: 'corresponding_kg_co22e'
                },
                {
                    label: 'Corresponding kgCO2e/m2 (optional)',
                    key: 'corresponding_kg_co22e_m2'
                },
                {
                    label: 'Reused content (% by value) (optional)',
                    key: 'reused_content'
                },
                {
                    label: 'Estimated reusable materials (kg/m2) (optional)',
                    key: 'estimate_reusable_materials'
                },
                {
                    label: 'Estimated recyclable materials (kg/m2) (optional)',
                    key: 'estimated_recyclable_materials'
                },
                {
                    label: 'Additional information',
                    key: 'additional_information'
                }
            ],
            rows: []
        },
        space: {
            fixed: false,
            cols: [
                {
                    label: 'Element',
                    key: 'element'
                },
                {
                    label: 'Material',
                    key: 'material'
                },
                {
                    label: 'Material quantity (kg)',
                    key: 'material_quantity'
                },
                {
                    label: 'Material intensity (kg/m2 GIA or NIA)',
                    key: 'material_intensity'
                },
                {
                    label: 'Recycled content (% by value)',
                    key: 'recycled_content'
                },
                {
                    label: 'Corresponding kgCO2e (optional)',
                    key: 'corresponding_kg_co22e'
                },
                {
                    label: 'Corresponding kgCO2e/m2 (optional)',
                    key: 'corresponding_kg_co22e_m2'
                },
                {
                    label: 'Reused content (% by value) (optional)',
                    key: 'reused_content'
                },
                {
                    label: 'Estimated reusable materials (kg/m2) (optional)',
                    key: 'estimate_reusable_materials'
                },
                {
                    label: 'Estimated recyclable materials (kg/m2) (optional)',
                    key: 'estimated_recyclable_materials'
                },
                {
                    label: 'Additional information',
                    key: 'additional_information'
                }
            ],
            rows: []
        }
    }
};
