import React, {Component} from 'react';
import BaseStatementScreen from "./base-statement-screen";
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import EditableTable from "../../../components/editable-table";
import {ECONOMY_STATEMENT_QUESTIONS, GLA2_STATEMENT_SCREEN} from "../../../lib/project/economy-statement-questions";

class GlaTwo extends BaseStatementScreen {

    constructor(props) {
        super(props);
        this.screen = GLA2_STATEMENT_SCREEN;
    }


    render() {

        const qs  = ECONOMY_STATEMENT_QUESTIONS[GLA2_STATEMENT_SCREEN];

        return (
            <div className={'sub-screen'}>

                <div className={`card card-collapsable mb-5 ${this.isOpen('section_a')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('section_a')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-two'} name={'section-a-info-link'} /> Section A: Conserve Resources

                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.section_a.cols}
                            fixed={qs.section_a.fixed}
                            rows={qs.section_a.rows}
                            values={this.getValues('section_a')}
                            onChange={(key, row, value) => {
                                this.handleChange('section_a', row, key, value);
                            }}
                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('section_b')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('section_b')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-two'} name={'section-b-info-link'} /> Section B: Design to Eliminate Waste (and for Ease of Maintenance)


                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.section_b.cols}
                            fixed={qs.section_b.fixed}
                            rows={qs.section_b.rows}
                            values={this.getValues('section_b')}
                            onChange={(key, row, value) => {
                                this.handleChange('section_b', row, key, value);
                            }}
                        />
                    </div>
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('section_c')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('section_c')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'gla-table-two'} name={'section-c-info-link'} /> Section C: Manage Waste
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.section_c.cols}
                            fixed={qs.section_c.fixed}
                            rows={qs.section_c.rows}
                            values={this.getValues('section_c')}
                            onChange={(key, row, value) => {
                                this.handleChange('section_c', row, key, value);
                            }}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default GlaTwo;
