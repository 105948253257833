import React, {Component} from 'react';

class LargeNavButton extends Component {
    render() {
        return (
            <img style={{width: '50px'}}  src={'/img/large_nav_button.svg'} className={'large-nav-button'} />
        );
    }
}

export default LargeNavButton;
