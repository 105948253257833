import React, {Component} from 'react';

class CardResult extends Component {
    render() {
        return (
            <div className={`card-result ${this.props.success ? 'success' : ''}`}>
                <div className="card-result__title">
                    {this.props.title}
                </div>
                <div className="card-result__value">
                    {this.props.value}
                </div>
            </div>
        );
    }
}

CardResult.defaultProps = {
    title: '',
    value: '',
    success : false
};

export default CardResult;
