import React, {Component} from 'react';
import Lang from '../lib/lang/lang';


const PREVIEW_LENGTH = 200;
class ReadMore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }


    getContent() {
        let section = Lang.getSection(this.props.section);
        if(!section) {
            return '';
        }
        if(!section[this.props.content]) {
            return '';
        }

        return section[this.props.content];
    }

    getPreview() {
       let content =  this.getContent();
       if(content.length <= PREVIEW_LENGTH) {
           return content;
       }
       return `${content.slice(0, PREVIEW_LENGTH)}...`;
    }

    render() {

        return (
            <div  className={'read-more'}>
                {
                    this.state.open === true &&
                    <div className="read-more__inner" dangerouslySetInnerHTML={{ __html : this.getContent()}}>
                    </div>
                }
                {
                    this.state.open === false &&
                    <div className="read-more__inner"  dangerouslySetInnerHTML={{ __html :this.getPreview()}}>

                    </div>
                }
                {
                    this.getContent().length > PREVIEW_LENGTH &&
                    <button onClick={() => this.setState({ open : !this.state.open})} className="btn read-more-button">Read More</button>

                }
            </div>
        );
    }
}

export default ReadMore;
