import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {ECONOMY_STATEMENT_QUESTIONS} from "../lib/project/economy-statement-questions";

class EditableTable extends Component {

    getValue(index, key) {
        return this.props.values[index] ? this.props.values[index][key] : '';
    }

    renderCol() {

    }

    addNewRow() {
        let currentSize = _.size(this.props.values);
        console.log(currentSize, 'current');
        if (currentSize === 0) {
            this.props.onChange(this.props.cols[0].key, 0, '');
            this.props.onChange(this.props.cols[0].key, 1, '');
            return;
        }

        this.props.onChange(this.props.cols[0].key, currentSize, '');

    }


    getRows() {
        if (this.props.questionName !== null && this.props.questionScreen !== null && this.props.fixed === true) {
            let qs = ECONOMY_STATEMENT_QUESTIONS[this.props.questionScreen][this.props.questionName];

            if (qs) {
                return qs.rows;
            }
        }

        return this.props.rows;
    }

    getRemainingRows() {

        if (this.props.fixed === true) {
            return this.getRows();
        }

        if (_.size(this.props.values) >= 1) {
            let c = Object.assign({}, this.props.values);
            delete c['0'];
            return c;
        }

        return {};
    }

    getCols() {

        if (this.props.questionName !== null && this.props.questionScreen !== null) {
            let qs = ECONOMY_STATEMENT_QUESTIONS[this.props.questionScreen][this.props.questionName];

            if (qs) {
                return qs.cols;
            }
        }

        return this.props.cols;
    }

    render() {
        return (
            <div className={'editable-table table-responsive'}>
                <table className="table">
                    <thead>
                    <tr>
                        {
                            this.props.fixed === true &&
                            <th></th>
                        }
                        {
                            this.getCols().map((item, index) => {

                                return (
                                    <th key={index}>{item.label}</th>
                                );
                            })
                        }
                        {
                            this.props.fixed === false &&
                            <th className={'editable-table__remove-header'}>Remove</th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {
                            this.props.fixed === false &&

                            this.getCols().map((col, colIndex) => {
                                return (
                                    <td key={colIndex}>
                                        {
                                            this.props.display === false &&
                                            <textarea
                                                value={this.getValue(0, col.key)}
                                                onChange={e => {
                                                    this.props.onChange(col.key, 0, e.target.value);
                                                }}
                                            />
                                        }
                                        {
                                            this.props.display === true &&
                                            this.getValue(0, col.key)
                                        }

                                    </td>
                                );
                            })
                        }
                        {
                            this.props.fixed === false &&
                            <td className={'editable-table__remove'}></td>

                        }
                    </tr>
                    {
                        _.map(this.getRemainingRows(), (item, row) => {

                            return (
                                <tr key={row}>
                                    {
                                        this.props.fixed &&
                                        <td className={'editable-table__fixed-label'}>
                                            {
                                                item.label
                                            }
                                        </td>
                                    }
                                    {
                                        this.getCols().map((col, colIndex) => {

                                            return (
                                                <td key={colIndex}>

                                                    {
                                                        this.props.display === false &&
                                                        <textarea
                                                            value={this.getValue(this.props.fixed === true ? item.key : row, col.key)}
                                                            onChange={e => {
                                                                this.props.onChange(col.key, this.props.fixed === true ? item.key : row, e.target.value);
                                                            }}
                                                        >
                                                        </textarea>
                                                    }
                                                    {
                                                        this.props.display === true &&
                                                        this.getValue(this.props.fixed === true ? item.key : row, col.key)
                                                    }
                                                </td>

                                            );
                                        })
                                    }
                                    {
                                        this.props.fixed === false &&
                                        <td className={'editable-table__remove'}>
                                            <button className={'btn btn--dark-green'}
                                                    onClick={() => this.props.onDeleteRow(row)}>Remove
                                            </button>
                                        </td>
                                    }
                                </tr>
                            );
                        })
                    }

                    </tbody>
                </table>
                {
                    (this.props.fixed === false && this.props.display === false) &&
                    <div className="editable-table__footer">
                        <img onClick={this.addNewRow.bind(this)} src="/img/plus_icon.svg" alt=""/>
                    </div>
                }

            </div>
        );
    }
}

EditableTable.defaultProps = {
    cols: [],
    data: [],
    questionName: null,
    questionScreen: null,
    fixed: false,
    values: {},
    display: false,
    onChange: (key, row, value) => {
    },
    onDeleteRow: (row) => {

    }
};

export default EditableTable;


let els = $('.editable-table-component');

els.each((index, item) => {
    ReactDOM.render(
        <EditableTable
            display={true}
            questionName={item.getAttribute('data-question-name')}
            questionScreen={item.getAttribute('data-question-screen')}
            values={JSON.parse(item.getAttribute('data-values'))}
            fixed={item.getAttribute('data-fixed') === 'true'}
        />,
        item
    )
})
