import React, {Component} from 'react';
import Project from "../../lib/project/project";
import {NEW_BUILD_ZONES_SECTION, PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION} from "./project-details";

class ZonesOverviewPopup extends Component {


    getNewBuildingZoned() {
        let newBuildingZones = this.props.project.getSection(PROJECT_DETAILS_STAGE, NEW_BUILD_ZONES_SECTION);
        if(newBuildingZones) {
            return  _.map(newBuildingZones.values, item => item);
        }

        return [];
    }

    getRefurbBuildingZoned() {
        let newBuildingZones = this.props.project.getSection(PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION);
        if(newBuildingZones) {
            return  _.map(newBuildingZones.values, item => item);
        }

        return [];
    }

    render() {


        return (
            <div className={`zones-overview-popup popup ${this.props.open ? 'open' : ''}`}>
                    <div className="popup-inner">
                        <div onClick={this.props.onClose} className="popup-close-button">
                            <img src="/img/large_nav_button_left.svg"  alt=""/>
                            <div className={'ml-2'}>Close Pop-up</div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <h1>Build Zone Info</h1>
                                <button onClick={this.props.onEditClick} className={'btn btn--dark-green'}>Modify Inputs</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Building Use</th>
                                                <th>Structural Form</th>
                                                <th>Gross Floor Area (m2)</th>
                                                <th>Site</th>
                                                <th>Structure</th>
                                                <th>Skin</th>
                                                <th>Services</th>
                                                <th>Space</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.getNewBuildingZoned().map((item, index) => {
                                                item = item.value;
                                                return(
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.building_use}</td>
                                                        <td>{item.structural_form}</td>
                                                        <td>{item.gross_floor_area_m2}</td>
                                                        <td>{item.site}</td>
                                                        <td>{item.structure}</td>
                                                        <td>{item.skin}</td>
                                                        <td>{item.services}</td>
                                                        <td>{item.space}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        {
                                            this.getRefurbBuildingZoned().map((item, index) => {
                                                item = item.value;
                                                return(
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.building_use}</td>
                                                        <td>{item.structural_form}</td>
                                                        <td>{item.gross_floor_area_m2}</td>
                                                        <td>{item.site}</td>
                                                        <td>{item.structure}</td>
                                                        <td>{item.skin}</td>
                                                        <td>{item.services}</td>
                                                        <td>{item.space}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

            </div>
        );
    }
}

ZonesOverviewPopup.defaultProps = {
    open : false,
    project: Project.make({})
};

export default ZonesOverviewPopup;
