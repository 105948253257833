import React, {Component} from 'react';
import ReactDom from 'react-dom';
import ProjectProgress from "../components/project-progress";
import RibaOne, {RIBA_STAGE} from "./screens/riba-one";
import Strategic, {STRATEGIC_STAGE} from "./screens/strategic";
import ProjectDetails, {PROJECT_DETAILS_STAGE} from "./screens/project-details";
import CircularityCriteria, {CIRCULARITY_CRITERIA_STAGE} from "./screens/circularity-criteria";
import CircularityCriteriaOverview, {CIRCULARITY_CRITERIA_OVERVIEW_STAGE} from "./screens/circularity-criteria-overview";
import CircularEconomyStatement, {CIRCULAR_ECONOMY_STATEMENT_STAGE} from "./screens/circular-economy-statement";
import ProjectSummary from "./screens/project-summary";
import Project from "../lib/project/project";
import {percentageComplete} from "../lib/helpers/project-helpers";

export const TOTAL_STAGES = 7;

export const SCREE_STAGES = {
    1 : RIBA_STAGE,
    2 : STRATEGIC_STAGE,
    3 : PROJECT_DETAILS_STAGE,
    4: CIRCULARITY_CRITERIA_STAGE,
    5 : CIRCULARITY_CRITERIA_OVERVIEW_STAGE
};

class ProjectTool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            screen: 1,
            project: {
                id: 1
            }

        };
    }

    componentDidMount() {
        this.setState({
            project: this.props.project,
            screen: this.getInitialScreen()
        });

    }


    getInitialScreen() {

        let p = Project.make(this.props.project);
        let maxScreen = 1;

        if (p.isStageComplete(RIBA_STAGE)) {
            maxScreen = 2;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(STRATEGIC_STAGE)) {
            maxScreen = 3;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(PROJECT_DETAILS_STAGE)) {
            maxScreen = 4;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(CIRCULARITY_CRITERIA_STAGE)) {
            maxScreen = 5;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(CIRCULARITY_CRITERIA_STAGE)) {
            maxScreen = 5;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(CIRCULARITY_CRITERIA_OVERVIEW_STAGE)) {
            maxScreen = 6;
        } else {
            return maxScreen;
        }

        if (p.isStageComplete(CIRCULAR_ECONOMY_STATEMENT_STAGE)) {
            maxScreen = 7;
        } else {
            return maxScreen;
        }


        return maxScreen;
    }

    getPercentageComplete(project) {
        return percentageComplete(project, TOTAL_STAGES);
    }

    saveProject(project, callback) {

        project.percentage_complete = this.getPercentageComplete(project);

        axios.post(`/projects/${this.state.project.id}`, project).then(() => {
            callback();
        });
    }

    handleNext() {
        window.scrollTo(0, 0);
        let current = this.state.screen;
        this.setState({screen: (current + 1)});
    }

    handleBack() {
        window.scrollTo(0, 0);
        let current = this.state.screen;
        if (current > 1) {
            this.setState({screen: (current - 1)})
        }
    }

    screen() {
        let props = {
            project: Project.make(Object.assign({}, this.state.project, {
                onUpdate: project => {
                    this.setState({
                        project: project.serialize()
                    });
                }
            })),
            setScreen : (screen) => this.setState({screen : screen}),
            onBack: project => this.saveProject(project, this.handleBack.bind(this)),
            onNext: project => this.saveProject(project, this.handleNext.bind(this))
        };

        switch (this.state.screen) {
            case 1 :
                return <RibaOne {...props} />;
            case 2 :
                return <Strategic {...props} />;
            case 3 :
                return <ProjectDetails {...props} />;
            case 4 :
                return <CircularityCriteria  {...props} />;
            case 5:
                return <CircularityCriteriaOverview {...props} />;
            case 6 :
                return <CircularEconomyStatement {...props} />;
            case 7 :
                props = Object.assign({}, props, {
                    onNext: project => {
                        this.saveProject(project, () => {
                            window.location = `/projects/${this.state.project.id}`;
                        });
                    }
                })
                return <ProjectSummary {...props} />
            default:
                return 'Loading';
        }
    }

    handleStageClick(screen) {
        let stage = SCREE_STAGES[screen];
        let p = Project.make(this.state.project);
        if(p.isStageComplete(stage)){
            this.saveProject(p, () => this.setState({screen : screen}));
        }
    }

    render() {
        return (
            <div className={'project-tool'}>
                {
                    this.state.error !== null &&
                    <div className="error-popup">

                    </div>
                }
                <ProjectProgress
                    project={Project.make(this.state.project)}
                    screen={this.state.screen}
                    onClick={this.handleStageClick.bind(this)}
                />
                <div className="project-tool__screens">
                    {
                        this.screen()
                    }
                </div>
            </div>
        );
    }
}

export default ProjectTool;

let el = document.getElementById('project-tool');

if (el) {
    ReactDom.render(
        <ProjectTool
            project={JSON.parse(el.getAttribute('data-project'))}
        />,
        el
    );
}
