import React, {Component} from 'react';
import ContentBase from "./content-base";

class Button extends ContentBase {
    render() {
        return (
            <a target={'_blank'} href={this.getLang()} className={this.props.className}>
                {
                    this.props.children
                }
            </a>
        );
    }
}


Button.defaultProps = {
    section : {},
    name : ''
};

export default Button;
