import React, {Component} from 'react';
import Lang from './lang';

class Section extends Component {

    constructor(props) {
        super(props);
        this.state = {
            section : null
        };
    }

    componentDidMount() {
        this.setSection();
    }

    setSection() {
        this.setState({
            section : Lang.getSection(this.props.name)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.name !== this.props.name) {
            this.setSection();
        }
    }

    render() {

        if(this.state.section == null) {
            return '';
        }

        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, Object.assign({}, child.props, { section : this.state.section}))
        })
    }
}

export default Section;
