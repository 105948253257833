import React, {Component} from 'react';
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import EditableTable from "../../../components/editable-table";
import {
    ECONOMY_STATEMENT_QUESTIONS, RECYCLING_WASTE_STATEMENT_SCREEN
} from "../../../lib/project/economy-statement-questions";
import BaseStatementScreen from "./base-statement-screen";

class RecyclingAndWaste extends BaseStatementScreen {

    constructor(props) {
        super(props);
        this.screen = RECYCLING_WASTE_STATEMENT_SCREEN;
    }


    render() {

        const qs = ECONOMY_STATEMENT_QUESTIONS[RECYCLING_WASTE_STATEMENT_SCREEN];

        return (
            <div className={'sub-screen'}>
                <div className={`card card-collapsable mb-5 ${this.isOpen('construction_waste_new')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('construction_waste_new')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'recycling-waste-gla'} name={'new-info-link'} /> New Build Reporting Form
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.construction_waste_new.cols}
                            fixed={qs.construction_waste_new.fixed}
                            rows={qs.construction_waste_new.rows}
                            values={this.getValues('construction_waste_new')}
                            onChange={(key, row, value) => {
                                this.handleChange('construction_waste_new', row, key, value);
                            }}
                        />
                    </div>
                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('construction_waste_existing')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('construction_waste_existing')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'recycling-waste-gla'} name={'existing-info-link'} /> Existing Building Reporting Form
                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.construction_waste_existing.cols}
                            fixed={qs.construction_waste_existing.fixed}
                            rows={qs.construction_waste_existing.rows}
                            values={this.getValues('construction_waste_existing')}
                            onChange={(key, row, value) => {
                                this.handleChange('construction_waste_existing', row, key, value);
                            }}
                        />
                    </div>
                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('municipal_waste')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('municipal_waste')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={'recycling-waste-gla'} name={'municipal-info-link'} /> Municipal Waste

                    </div>
                    <div className="card-body card-body--no-padding">
                        <EditableTable
                            cols={qs.municipal_waste.cols}
                            fixed={qs.municipal_waste.fixed}
                            rows={qs.municipal_waste.rows}
                            values={this.getValues('municipal_waste')}
                            onChange={(key, row, value) => {
                                this.handleChange('municipal_waste', row, key, value);
                            }}
                        />
                    </div>
                </div>


            </div>
        );
    }
}

export default RecyclingAndWaste;
