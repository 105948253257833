import Stage from "./stage";
import Collection from "./collection";

export class StageCollection  extends  Collection {
    constructor(stages = []) {
        super(stages.map(params => Stage.make(params)));
    }




}
