import React, {Component} from 'react';

class ZoneResponse extends Component {

    getAwardedDisplay() {
        let t = this.props.credits_awarded;
        if ((t - Math.floor(t)) !== 0) {
            return this.props.credits_awarded.toFixed(2);
        }

        return t;
    }

    render() {
        return (
            <div className="form-group form-group--reverse w-50 pr-4">
                <label htmlFor="">{this.props.zone.name}</label>
                <select disabled={this.props.disabled} value={this.props.value}
                        onChange={e => this.props.onChange(e.target.value)}
                        className="form-control mr-4">
                    <option>Select option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                    <option value="to be determined">To be determined</option>
                </select>
                <label htmlFor="">Credits awarded:</label>
                <div className={'points-badge'}>{this.getAwardedDisplay()}</div>
            </div>
        );
    }
}

ZoneResponse.defaultProps = {
    zone: {},
    credits_awarded: 1
};


export default ZoneResponse;
