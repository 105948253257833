import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Project from "../lib/project/project";
import CreditSummary from "./credit-summary";
import {CIRCULARITY_CRITERIA_STAGE} from "../containers/screens/circularity-criteria";
import {DESIGN_FOR_ADAPTABILITY} from "../containers/screens/sub-screens/design-for-adaptability";
import {DESIGN_FOR_DECONSTRUCTION} from "../containers/screens/sub-screens/design-for-deconstruction";
import {CIRCULAR_MATERIAL_SELECTION} from "../containers/screens/sub-screens/circular-material-selection";
import {RESOURCE_EFFICIENCY} from "../containers/screens/sub-screens/resource-efficiency";
import {CreditSummaryStatsFactory} from "../lib/project/credit-summary-stats-factory";

class CreditsSummaryEmbedded extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {}
        };
    }


    render() {
        let project = Project.make(this.props.project);
        let factory = new CreditSummaryStatsFactory(project);

        return (
            <div>
                <CreditSummary
                    title={'Design for Adaptability'}
                    selectedPrinciple={project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', DESIGN_FOR_ADAPTABILITY)}
                    stats={factory.designForAdaptability()}
                />
                <hr/>
                <CreditSummary
                    title={'Design for Deconstruction'}
                    selectedPrinciple={project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', DESIGN_FOR_DECONSTRUCTION)}
                    stats={factory.designForDeconstruction()}
                />
                <hr/>
                <CreditSummary
                    title={'Circular Material Selection'}
                    selectedPrinciple={project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', CIRCULAR_MATERIAL_SELECTION)}
                    stats={factory.circularMaterialSelection()}
                />
                <hr/>
                <CreditSummary
                    title={'Resource Efficiency'}
                    selectedPrinciple={project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', RESOURCE_EFFICIENCY)}
                    stats={factory.resourceEfficiency()}
                />
            </div>
        );
    }
}

export default CreditsSummaryEmbedded;


let el = document.getElementById('credits-summary-embedded');

if(el) {
    ReactDOM.render(
        <CreditsSummaryEmbedded project={JSON.parse(el.getAttribute('data-project'))} />,
        el
    );
}
