import React, {Component} from 'react';
import ReactDOM from "react-dom";
import ProjectProgress from "./project-progress";

class ProgressBar extends Component {
    render() {
        return (
            <div className="progress-bar">
                <div className="progress-bar__title">
                    Project Progress
                </div>
                <div className="progress-bar__wrapper">
                    <div className="progress-bar__container">
                        <div className="progress-bar__inner" style={{left : `-${100 - this.props.complete}%`}}></div>
                    </div>
                </div>
            </div>
        );
    }
}

ProgressBar.defaultProps  = {
    complete : 0
};

export default ProgressBar;


$('.progress-bar-component').each((index, item) => {
    ReactDOM.render(
        <ProgressBar complete={item.getAttribute('data-progress')} />,
        item
    );
});
