import {
    NEW_BUILD_ZONES_SECTION,
    PROJECT_DETAILS_STAGE,
    REFURB_BUILD_ZONES_SECTION
} from "../../containers/screens/project-details";
import {AIM_REQUIRED_QUESTIONS} from "./aim-required-questions";

export class CreditsCalculator {
    constructor(project) {
        this.project = project;
    }

    eachZone(callback) {
        let newBuildingZones = this.project.getSection(PROJECT_DETAILS_STAGE, NEW_BUILD_ZONES_SECTION);
        let refurbBuildingZones = this.project.getSection(PROJECT_DETAILS_STAGE, REFURB_BUILD_ZONES_SECTION);

        if (newBuildingZones) {
            newBuildingZones.values.forEach(item => {
                callback(item);
            });
        }

        if (refurbBuildingZones) {
            refurbBuildingZones.values.forEach(item => {
                callback(item);
            })
        }
    }

    calculateLayerAvailableCredits(categoryPrefix, layer, principleAim = 'full') {
        let qs = AIM_REQUIRED_QUESTIONS[categoryPrefix][layer][principleAim];
        let total = 0;
        qs.forEach(q => {
            total = (total + this.calculateQuestionAvailableCredits(`${categoryPrefix}${q}`));
        });
        return total;
    }

    calculateQuestionAvailableCredits(question) {
        let total = 0;
        this.eachZone(zone => {
            total = (total + this.calculateAvailableCredits(zone));
        });
        return total;
    }

    calculateLayerAwardedCredits(categoryPrefix, layer) {
        let qs = AIM_REQUIRED_QUESTIONS[categoryPrefix][layer].full;
        let total = 0;
        qs.forEach(q => {
           total = (this.calculateQuestionTotalCredits(layer, `${categoryPrefix}${q}`) + total);
        });

        return total;
    }

    calculateQuestionTotalCredits(layer, question) {
        let total = 0;
        this.eachZone(zone => {
            total = (total + this.calculateCreditsAwarded(zone, layer, question));
        });

        return total;
    }

    calculateCreditsAwarded(zone, layer, question) {

        let available = this.calculateAvailableCredits(zone);

        if (zone.value[layer] === 'no') {
            return available;
        }

        if (zone.value[question]) {
            return zone.value[question] === 'yes' ? available : 0;
        }

        return 0;
    }

    calculateAvailableCredits(zone) {
        if (!zone.value.gross_floor_area_m2) {
            return;
        }

        let totalArea = 0;
        let totalZones = 0;

        this.eachZone(item => {
            if (item.value.gross_floor_area_m2) {
                totalArea = (totalArea + parseInt(item.value.gross_floor_area_m2));
                totalZones++;

            }
        })

        let credit =  (1 / totalArea) * parseInt(zone.value.gross_floor_area_m2);


        return credit;

    }



}
