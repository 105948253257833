import Project from "../project/project";

export function percentageComplete(project, totalStages = 7) {
    let completed = 0;
    let p = Project.make(project);
    p.stages.each(stage => {
        if (stage.complete === true) {
            completed++;
        }
    });
    return Math.ceil((100 / totalStages) * completed);
}
