import React, {Component} from 'react';
import {Pie} from "react-chartjs-2";
import {DEFAULT_PIE_CHART_SETTINGS, DEFAULT_CHART_COLORS} from "../lib/project/default-pie-settings";
import {CreditSummaryStatsFactory} from "../lib/project/credit-summary-stats-factory";
import ReactDOM from "react-dom";
import Project from "../lib/project/project";
import CreditsPrincipleGraph from "./credits-principle-graph";
import colors from "../lib/helpers/colors";


const LABEL_COLORS  = {
    'Remaining Credits' : '#ffffff',
    'Site' : colors[1],
    'Structure' : colors[2],
    'Skin' : colors[3],
    'Services' : colors[4],
    'Space' : colors[5]
};


class CreditsBuildingLayerGraph extends Component {

    getColor(l) {
        let c = LABEL_COLORS[l];

        return c ? c : '#ffffff';
    }

    statsToParams(stats) {
        let data = [
            (stats.totals.available_credits - stats.totals.credit_total),
            stats.site.credit_total,
            stats.structure.credit_total,
            stats.skin.credit_total,
            stats.services.credit_total,
            stats.space.credit_total
        ];
        let labels = ['Remaining Credits', 'Site', 'Structure', 'Skin', 'Services', 'Space'];
        let props = {
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: labels.map(l => this.getColor(l))

                    }
                ]
            },
            options : Object.assign({}, DEFAULT_PIE_CHART_SETTINGS.options, {
                legend: {
                    display : true,
                    labels: {
                        fontSize: this.props.large === true ? 20 : 12
                    }
                }
            })
        };


        return Object.assign({}, DEFAULT_PIE_CHART_SETTINGS, props);

    }

    adaptability() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        return this.statsToParams(factory.designForAdaptability());
    }

    deconstruction() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        return this.statsToParams(factory.designForDeconstruction());
    }

    selection() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        return this.statsToParams(factory.circularMaterialSelection());
    }

    efficiency() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        return this.statsToParams(factory.resourceEfficiency());
    }


    getBigProps() {

        return {
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        data: [65, 59, 80, 81, 56, 55, 40]

                    }
                ]
            },
            options: {
                maintainAspectRatio: true,
                responsive: true,
                legend: true
            }
        };

    }

    render() {
        return (
            <div className={'credits-building-layer-graph'}>
                <div className="row">

                    <div className="col-12 col-md-6  mt-5">
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                Design for Adaptability
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Pie {...this.adaptability()} />

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6  mt-5">
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                Design for Deconstruction

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <Pie {...this.deconstruction()} />

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6  mt-5">
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                Circular Material Selection

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Pie {...this.selection()} />

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6  mt-5">
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                Resource Efficiency
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Pie {...this.efficiency()} />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }


}


CreditsBuildingLayerGraph.defaultProps = {
    project: {},
    showLegend : false,
    large : false
};

export default CreditsBuildingLayerGraph;




let el = document.getElementById('credits-building-layer-graph');

if(el) {
    ReactDOM.render(
        <CreditsBuildingLayerGraph
            project={Project.make(JSON.parse(el.getAttribute('data-project')))}
            showLegend={true}
            large={$(el).hasClass('large-format')}

        />,
        el
    );
}
