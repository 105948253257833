import React, {Component} from 'react';
import ContentBase from "./content-base";

class Label extends ContentBase {
    render() {
        return (
            <label className={this.props.className} dangerouslySetInnerHTML={{__html: this.getLang()}}>

            </label>
        );
    }
}

Label.defaultProps = {
    section : {}
};

export default Label;
