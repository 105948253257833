import React, {Component} from 'react';
import CardTotal from "../card-total";

class ZoneQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showReferences: false
        };
    }


    getValue(name) {
        return this.props.values[name] ? this.props.values[name] : '';
    }

    getAwardedDisplay() {
        let t = this.props.creditsTotal;
        if ((t - Math.floor(t)) !== 0) {
            return t.toFixed(2);
        }

        return t;
    }

    render() {
        return (

            <div className="card-body card-body--sm-padding">
                <div className="card card-inner">
                    <div className="card-body card-body--sm-padding">
                        <div className="row mb-5">
                            <div className="col-12 d-flex align-items-start justify-content-between">
                                <div className={'d-flex flex-column'}>
                                    <div className={'mb-2'}>
                                        <h4 className={'dark-green-text'}>{this.props.title}</h4>
                                    </div>
                                    <div className="mb-2">
                                        <h5>{this.props.question}</h5>
                                    </div>
                                    <div className={'d-flex flex-row justify-content-start'}>
                                        <button
                                            onClick={() => this.setState({showReferences: !this.state.showReferences})}
                                            className="btn mr-4">Additional Information
                                        </button>
                                        <button onClick={this.props.onZoneInfoClick} className="btn btn--purple">Build
                                            zone info
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <CardTotal
                                        value={this.getAwardedDisplay()}
                                        required={this.props.required}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            this.state.showReferences &&
                            <div className={'card-info mb-5'}>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.props.references.map((item, index) => {
                                                return (
                                                    <div key={index} className={'row mb-3'}>
                                                        <div className="col-2">
                                                            <div className="row mb-2">
                                                                <div className="col-12 purple-text">
                                                                    GLA Principle Reference
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h2>{item.reference}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="row mb-2">
                                                                <div className="col-12 purple-text">
                                                                    Additional Information
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12"
                                                                     dangerouslySetInnerHTML={{__html: item.additionalInfo}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="row mb-2">
                                                                <div className="col-12 purple-text">
                                                                    Resources
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <a target={'_blank'} href={item.link}
                                                                       className={'btn btn--white'}>Show</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }


                        <React.Fragment>
                            {
                                this.props.children
                            }
                        </React.Fragment>
                        <div className="row">
                            <div className="col-12">
                                <textarea
                                    value={this.getValue('additional_notes')}
                                    name='additional_notes'
                                    onChange={e => this.props.onChange(e)}
                                    placeholder={'Notes (e.g. evidence, supporting statements or justifications)'}
                                    className="form-control">
                                </textarea>
                            </div>
                        </div>
                        {
                            this.props.extraMeta.map((m, index) => {
                                return (
                                    <div key={index} className="row mt-4">
                                        <div className="col-12">
                                            <textarea
                                                value={this.getValue(m.name)}
                                                name={m.name}
                                                onChange={e => this.props.onChange(e)}
                                                placeholder={m.label}
                                                className="form-control">
                                            </textarea>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }

}

ZoneQuestion.defaultProps = {
    title: '',
    question: '',
    references: [],
    values: {},
    creditsTotal: 0,
    required: true,
    onZoneInfoClick: () => console.log('Zone Info Click'),
    extraMeta: []
};

export default ZoneQuestion;
