import React, {Component} from 'react';
import InfoIcon from "../../components/info-icon";
import CardResult from "../../components/card-result";
import Section from "../../lib/lang/section";
import Content from "../../lib/lang/content";
import Label from "../../lib/lang/label";
import Button from "../../lib/lang/button";
import ReadMore from "../../components/read-more";

export const STRATEGIC_STAGE = 'strategic_stage';
const RVEM_SECTION = 'Residual Value of Existing Materials';
const FVNM_SECTION = 'Future Value of New Materials';
const INCOMPLETE = 'Incomplete';

class Strategic extends Component {


    getValue(section, key) {
        return this.props.project.getValue(STRATEGIC_STAGE, section, key);
    }

    updateValue(section, key, value) {
        return this.props.project.updateValue(STRATEGIC_STAGE, section, key, value);
    }

    deleteValues(section, keys) {
        return this.props.project.deleteValues(STRATEGIC_STAGE, section, keys);
    }

    isR4Disabled() {


        if (this.getValue(RVEM_SECTION, '1') === 'yes' && this.getValue(RVEM_SECTION, '3') === 'no') {
            return false;
        }

        if (this.getValue(RVEM_SECTION, '1') === 'no') {
            return false;
        }
        return true;

    }

    getRVEMApproach() {
        if (this.getValue(RVEM_SECTION, '1') === 'no'
            && this.getValue(RVEM_SECTION, '2') === 'yes'
        ) {
            return 'Reuse materials';
        }

        if(this.getValue(RVEM_SECTION, '3') === 'no'
            && this.getValue(RVEM_SECTION, '1') === 'no'
        ) {
            return 'Not Applicable';
        }


        if (this.getValue(RVEM_SECTION, '1') === 'no'
            && this.getValue(RVEM_SECTION, '2') === 'no'
        ) {
            return 'Recycle materials';
        }

        if (this.getValue(RVEM_SECTION, '1') === 'yes'
            && this.getValue(RVEM_SECTION, '5') === 'no'
            && this.getValue(RVEM_SECTION, '2') === 'no'
        ) {
            return 'Demolish and recycle';
        }

        if (this.getValue(RVEM_SECTION, '1') === 'yes'
            && this.getValue(RVEM_SECTION, '5') === 'no'
            && this.getValue(RVEM_SECTION, '2') === 'yes'
        ) {
            return 'Deconstruct and reuse';
        }

        if (this.getValue(RVEM_SECTION, '1') === 'yes'
            && this.getValue(RVEM_SECTION, '5') === 'yes'
            && this.getValue(RVEM_SECTION, '4') === 'no'
        ) {
            return 'Repurpose';
        }


        if (this.getValue(RVEM_SECTION, '1') === 'yes'
            && this.getValue(RVEM_SECTION, '5') === 'yes'
            && this.getValue(RVEM_SECTION, '4') === 'yes'
        ) {
            return 'Refurbish';
        }


        return INCOMPLETE;
    }

    getFVNWApproach() {
        if(this.getValue(FVNM_SECTION, '1') === 'permanent'
            && this.getValue(FVNM_SECTION, '3') === '<5'
        ) {
            return 'Design for flexibility';
        }

        if(this.getValue(FVNM_SECTION, '1') === 'permanent'
            && this.getValue(FVNM_SECTION, '3') === '5-25'
        ) {
            return 'Design for adaptability';
        }

        if(this.getValue(FVNM_SECTION, '1') === 'permanent'
            && this.getValue(FVNM_SECTION, '3') === '>25'
        ) {
            return 'Design for longevity';
        }

        if(this.getValue(FVNM_SECTION, '1') === 'temporary'
            && this.getValue(FVNM_SECTION, '2') === 'yes'
        ) {
            return 'Design for reusability';
        }

        if(this.getValue(FVNM_SECTION, '1') === 'temporary'
            && this.getValue(FVNM_SECTION, '2') === 'no'
        ) {
            return 'Design for recoverability';
        }

        return INCOMPLETE;
    }


    isComplete() {
        return this.getRVEMApproach() === INCOMPLETE || this.getFVNWApproach() === INCOMPLETE;
    }

    isDisabled(callback) {
        return callback();
    }

    hasAnswer(section, key) {
        if(this.getValue(section, key) === 'yes') {
            return true;
        }

        if(this.getValue(section, key) === 'no') {
            return true;
        }
        return false;
    }

    render() {

        return (
            <div className={'screen'}>
                <div className="container">
                    <div className="row screen__header">
                        <div className="col-5">
                            <h1 className="screen__title">
                                Strategic Approaches
                            </h1>
                            <Section name={'strategic-approaches'}>
                                <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                            </Section>
                        </div>
                        <div className="col-7">
                            <ReadMore section={'strategic-approaches'} content={'stage-description'} />
                        </div>
                    </div>
                    <div className="screen__body">
                        <div className="card mb-5">
                            <div className="card-header card-header--dark-green">
                                <InfoIcon section={'strategic-approaches'} name={'residual-value-info-link'}/> Residual Value of Existing Materials
                                <CardResult
                                    title={'Advised Approach'}
                                    value={this.getRVEMApproach()}
                                    success={this.getRVEMApproach() !== INCOMPLETE}
                                />
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                        <Label name={'residual-values-q1'} />
                                                </Section>
                                                <select value={this.getValue(RVEM_SECTION, '1')}
                                                        onChange={e => {
                                                            this.deleteValues(RVEM_SECTION, ['2', '3', '4', '5']);
                                                            this.updateValue(RVEM_SECTION, '1', e.target.value);
                                                        }}
                                                        className="form-control">
                                                    <option>Select Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'residual-values-q2'} />
                                                </Section>
                                                <select
                                                    onChange={e => this.updateValue(RVEM_SECTION, '2', e.target.value)}
                                                    value={this.getValue(RVEM_SECTION, '2')}
                                                    disabled={this.isDisabled(() => {
                                                        if(this.getValue(RVEM_SECTION, '3') === 'yes') {
                                                            return false;
                                                        }

                                                        if(this.getValue(RVEM_SECTION, '5') === 'no') {
                                                            return false;
                                                        }
                                                        return true;
                                                    })}
                                                    className="form-control">

                                                    <option>Select Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'residual-values-q3'} />
                                                </Section>
                                                <select disabled={this.isDisabled(() => {
                                                        if(this.hasAnswer(RVEM_SECTION, '1') === false) {
                                                            return true;
                                                        }

                                                    if(this.getValue(RVEM_SECTION, '1') === 'no') {

                                                            return false;
                                                        }


                                                        return true;

                                                  })}
                                                        value={this.getValue(RVEM_SECTION, '3')}
                                                        onChange={e => {
                                                            this.updateValue(RVEM_SECTION, '3', e.target.value);
                                                            this.deleteValues(RVEM_SECTION, ['2']);

                                                        }}

                                                        className="form-control">
                                                    <option>Select Option</option>

                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'residual-values-q4'} />
                                                </Section>
                                                <select
                                                    disabled={this.isDisabled(() => {

                                                        if(this.getValue(RVEM_SECTION, '5') === 'yes') {

                                                            return false;
                                                        }


                                                        return true;

                                                    })}
                                                    value={this.getValue(RVEM_SECTION, '4')}
                                                    onChange={e => this.updateValue(RVEM_SECTION, '4', e.target.value)}
                                                    className="form-control">
                                                    <option>Select Option</option>

                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'residual-values-q5'} />
                                                </Section>
                                                <select value={this.getValue(RVEM_SECTION, '5')}
                                                        onChange={e => {
                                                            this.updateValue(RVEM_SECTION, '5', e.target.value);
                                                            this.deleteValues(RVEM_SECTION, ['2', '4']);
                                                        }}
                                                        disabled={this.isDisabled(() => {

                                                            if(this.getValue(RVEM_SECTION, '1') === 'yes') {
                                                                return false;
                                                            }

                                                            return true;
                                                        })}
                                                        className="form-control">
                                                    <option>Select Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-header card-header--dark-green">
                                <InfoIcon section={'strategic-approaches'} name={'future-value-info-link'}/> Future Value of New Materials <CardResult
                                title={'Advised Approach'}
                                value={this.getFVNWApproach()}
                                success={this.getFVNWApproach() !== INCOMPLETE}

                            />
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'future-value-q1'} />
                                                </Section>
                                                <select
                                                    value={this.getValue(FVNM_SECTION, '1')}
                                                    onChange={e => {
                                                        this.updateValue(FVNM_SECTION, '1', e.target.value);
                                                        this.deleteValues(FVNM_SECTION, ['2', '3'])
                                                    }}
                                                    className="form-control">
                                                    <option>Select Option</option>
                                                    <option value="permanent">Permanent</option>
                                                    <option value="temporary">Temporary</option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'future-value-q2'} />
                                                </Section>
                                                <select
                                                    value={this.getValue(FVNM_SECTION, '2')}
                                                    onChange={e => this.updateValue(FVNM_SECTION, '2', e.target.value)}
                                                    disabled={this.getValue(FVNM_SECTION, '1') !== 'temporary'}
                                                    className="form-control">
                                                    <option>Select Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group form-group--reverse">
                                                <Section name={'strategic-approaches'} >
                                                    <Label name={'future-value-q3'} />
                                                </Section>
                                                <select
                                                    disabled={this.getValue(FVNM_SECTION, '1') !== 'permanent'}
                                                    value={this.getValue(FVNM_SECTION, '3')}
                                                    onChange={e => this.updateValue(FVNM_SECTION, '3', e.target.value)}
                                                    className="form-control">
                                                    <option value="">Select Option</option>
                                                    <option value="<5">Less than 5 years</option>
                                                    <option value="5-25">5 - 25 years</option>
                                                    <option value=">25">More than 25 years</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div className="screen__footer d-flex">
                        <button onClick={() => this.props.onBack(this.props.project)} className="btn btn--ex-large btn--purple mr-auto">Save and
                            return
                        </button>
                        <button disabled={this.isComplete()} onClick={() => {
                            this.props.project.stageComplete(STRATEGIC_STAGE);
                            this.props.onNext(this.props.project)
                        }}
                                className="btn btn--ex-large ml-auto">Save and proceed
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Strategic;
