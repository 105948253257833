import React, {Component} from 'react';
import BaseSubScreen, {CARD_TITLE} from "./base-sub-screen";
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import CardStatus from "../../../components/card-status";
import {AIM_REQUIRED_QUESTIONS, CIRCULAR_MATERIAL_SELECTION_PREFIX} from "../../../lib/project/aim-required-questions";

export const CIRCULAR_MATERIAL_SELECTION = 'circular_material_selection';

class CircularMaterialSelection extends BaseSubScreen {

    constructor(props) {
        super(props);
        this.state = {
            open: {}
        };

        this.stage = CIRCULAR_MATERIAL_SELECTION;
        this.prefix = CIRCULAR_MATERIAL_SELECTION_PREFIX;
        this.namedPrefix = 'CMS';
        this.contentSection = 'circular-material-selection';

    }

    render() {
        let q = AIM_REQUIRED_QUESTIONS[CIRCULAR_MATERIAL_SELECTION_PREFIX];
        return (
            <div className={'sub-screen'}>


                <div className={`card card-collapsable mb-5 ${this.isOpen('site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('site')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'site-info-link'}/> Site <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('site', q.site)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'site',
                            'Where demolition / deconstruction of (part of) an existing structure is taking place, has a pre-demolition audit been completed? (if no demolition / deconstruction is taking place, respond \'Yes\')',
                            1
                        )
                    }

                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('structure')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('structure')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'structure-info-link'}/> Structure <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('structure', q.structure)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'structure',
                            'Materials, components and products sourced as part of a leasing / buy-back scheme',
                            2
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Materials, components and products registered with a reclamation database',
                            3
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Use of toxic or hazardous materials and coatings is minimised',
                            4
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Biological materials remain uncontaminated to allow for future return to nature',
                            5
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Composition of structural elements ensures future reusability',
                            6
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Material ingredient lists declared (including finishing material such as paints)',
                            7
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'The use of secondary finishes has been avoided',
                            8
                        )
                    }
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('skin')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('skin')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'skin-info-link'}/> Skin <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('skin', q.skin)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'skin',
                            'Materials, components and products sourced as part of a leasing / buy-back scheme',
                            9
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Materials, components and products registered with a reclamation database',
                            10
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Use of toxic or hazardous materials and coatings is minimised',
                            11
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Biological materials remain uncontaminated to allow for future return to nature',
                            12
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Material ingredient lists declared (including finishing material such as paints)',
                            13
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'The use of secondary finishes has been avoided',
                            14
                        )
                    }
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('services')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('services')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'services-info-link'}/> Services <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('services', q.services)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'services',
                            'Materials, components and products sourced as part of a leasing / buy-back scheme',
                            15
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Materials, components and products registered with a reclamation database',
                            16
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Use of toxic or hazardous materials and coatings is minimised',
                            17
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Biological materials remain uncontaminated to allow for future return to nature',
                            18
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'Material ingredient lists declared (including finishing material such as paints)',
                            19
                        )
                    }
                    {
                        this.renderQuestion(
                            'services',
                            'The use of secondary finishes has been avoided',
                            20
                        )
                    }

                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('space')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('space')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'space-info-link'}/> Space <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('space', q.space)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'space',
                            'Materials, components and products sourced as part of a leasing / buy-back scheme',
                            21
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Materials, components and products registered with a reclamation database',
                            22
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Use of toxic or hazardous materials and coatings is minimised',
                            23
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Biological materials remain uncontaminated to allow for future return to nature',
                            24
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Material ingredient lists declared (including finishing material such as paints)',
                            25
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'The use of secondary finishes has been avoided',
                            26
                        )
                    }
                </div>
            </div>
        );
    }
}

export default CircularMaterialSelection;
