import React, {Component} from 'react';
import BillOfMaterials from "./statement-screens/bill-of-materials";
import RecyclingAndWaste from "./statement-screens/recycling-and-waste";
import {
    BILL_OF_MATERIALS_STATEMENT_SCREEN, GLA1_STATEMENT_SCREEN, GLA2_STATEMENT_SCREEN,
    RECYCLING_WASTE_STATEMENT_SCREEN
} from "../../lib/project/economy-statement-questions";
import GlaOne from "./statement-screens/GLA-One";
import GlaTwo from "./statement-screens/GLA-Two";
import Content from "../../lib/lang/content";
import Section from "../../lib/lang/section";
import Button from "../../lib/lang/button";
import ReadMore from "../../components/read-more";


export const CIRCULAR_ECONOMY_STATEMENT_STAGE = 'circular_economy_statement_stage';

import { stickIt} from "../../lib/helpers/sticky";

class CircularEconomyStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subScreen: BILL_OF_MATERIALS_STATEMENT_SCREEN
        };

        this.stickyContainer = null;
    }

    activeTab(screen) {
        return screen === this.state.subScreen ? 'active' : '';
    }

    subScreen() {

        let props = {
            project: this.props.project
        };

        switch (this.state.subScreen) {
            case BILL_OF_MATERIALS_STATEMENT_SCREEN :
                return <BillOfMaterials {...props} />;
            case RECYCLING_WASTE_STATEMENT_SCREEN :
                return <RecyclingAndWaste {...props} />;
            case GLA1_STATEMENT_SCREEN :
                return <GlaOne {...props} />;
            case GLA2_STATEMENT_SCREEN :
                return <GlaTwo {...props} />;
        }

    }

    getContentSection() {
        switch (this.state.subScreen) {
            case BILL_OF_MATERIALS_STATEMENT_SCREEN :
                return 'bill-materials';
            case RECYCLING_WASTE_STATEMENT_SCREEN :
                return 'recycling-waste-gla';
            case GLA1_STATEMENT_SCREEN :
                return 'gla-table-one';
            case GLA2_STATEMENT_SCREEN :
                return 'gla-table-two';
        }
    }
    componentDidMount() {
        stickIt(this.stickyContainer);
    }

    render() {
        return (
            <div className={'screen circular-economy-statement'}>
                <div className="container">

                    <div className="row  screen__header mb-2">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h1 className="screen__title">Circular Economy Statement Preparation</h1>
                            <Section name={'circularity-economy-statement'}>
                                <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                            </Section>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="col-6 pr-5">
                            <h5 className={'semi-heavy-text purple-text'}>Introduction</h5>
                            <ReadMore section={'circularity-economy-statement'} content={'introduction'} />

                            {/*<Section name={'circularity-economy-statement'}>*/}
                            {/*    <Content name={'introduction'} />*/}
                            {/*</Section>*/}
                            {/*<button className="btn">Read More</button>*/}
                        </div>

                        {/*<div className="col-6">*/}
                        {/*    <h5 className={'semi-heavy-text purple-text'}>CE Statement Outputs</h5>*/}
                        {/*    <Section name={'circularity-economy-statement'}>*/}
                        {/*        <Content name={'output'} />*/}
                        {/*    </Section>*/}
                        {/*</div>*/}

                    </div>

                    <div ref={r => this.stickyContainer = r} className="screen__tabs mb-5 row   container">
                        <div className="col-12   d-flex justify-content-between align-items-center ">
                            <div className={'label'}>Statement Categories:</div>
                            <div>
                                <button onClick={() => this.setState({subScreen: BILL_OF_MATERIALS_STATEMENT_SCREEN})}
                                        className={`btn btn--tab ${this.activeTab(BILL_OF_MATERIALS_STATEMENT_SCREEN)}`}>Bill
                                    of Materials
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: RECYCLING_WASTE_STATEMENT_SCREEN})}
                                        className={`btn btn--tab ${this.activeTab(RECYCLING_WASTE_STATEMENT_SCREEN)}`}>Recycling and Waste
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: GLA1_STATEMENT_SCREEN})}
                                        className={`btn btn--tab ${this.activeTab(GLA1_STATEMENT_SCREEN)}`}>GLA Table One
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: GLA2_STATEMENT_SCREEN})}
                                        className={`btn btn--tab ${this.activeTab(GLA2_STATEMENT_SCREEN)}`}>GLA Table Two
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="screen__body">
                        <div className="row mt-5 mb-5">
                            <div className="col-12">
                                <Section name={this.getContentSection()}>
                                    <Content name={'description'} />
                                </Section>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {
                                    this.subScreen()
                                }
                            </div>
                        </div>

                    </div>


                    <div className="screen__footer d-flex">
                        <button onClick={() => this.props.onBack(this.props.project)}
                                className="btn btn--ex-large btn--purple mr-auto">Save and return
                        </button>
                        <button onClick={() => {
                            this.props.project.updateValue(CIRCULAR_ECONOMY_STATEMENT_STAGE, 'touched', 'complete', "true");
                            this.props.project.stageComplete(CIRCULAR_ECONOMY_STATEMENT_STAGE);
                            this.props.onNext(this.props.project);
                        }} className="btn btn--ex-large ml-auto">Save and proceed
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}

export default CircularEconomyStatement;
