import React, {Component} from 'react';
import Section from "../../lib/lang/section";
import Content from "../../lib/lang/content";
import ReadMore from "../../components/read-more";

export const RIBA_STAGE = 'riba_stage';

class RibaOne extends Component {


    constructor(props) {
        super(props);
        this.state = {};
    }


    stageValue() {
        return this.props.project.getValue(RIBA_STAGE, 'main', 'stage');
    }

    getContent(postfix) {
        switch (this.stageValue()) {
            case '1-2': {
                name = `1-2-${postfix}`;
                break;
            }
            case '2-3': {
                name = `2-3-${postfix}`;
                break;
            }
            case '3-4': {
                name = `3-4-${postfix}`;
                break;
            }
            default :
                name = '';
        }

        return(<Content className={'alert-info w-75'} name={name} />);
    }

    userResponses() {

        return this.getContent('user-responses');

    }

    toolOutput() {
        return this.getContent('tool-output');
    }

    toolObjectives() {
       return this.getContent('tool-objectives');
    }

    render() {
        const PROJECT = this.props.project;
        return (
            <div className={'screen screen--green-bg'}>
                <div className="container">

                    <div className="row screen__header">
                        <div className="col-5">
                            <h1 className="screen__title text-white">
                                Begin your new project
                            </h1>
                        </div>
                        <div className="col-7">
                            <ReadMore section={'riba-stage'} content={'stage-description'} />
                        </div>
                    </div>

                    <div className="screen__body">
                        <div className="card">
                            <div className="card-header">
                                Assessment Scope
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label>Project Stage</label>
                                        <select
                                            value={this.stageValue()}
                                            onChange={e => PROJECT.updateValue(RIBA_STAGE, 'main', 'stage', e.target.value)}
                                            className="form-control w-75"
                                        >
                                            <option value="">Select Option</option>
                                            <option value="1-2">Pre-design (RIBA 1-2)</option>
                                            <option value="2-3">Concept design (RIBA 2-3)</option>
                                            <option value="3-4">Detailed design and construction (RIBA 4-7)</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>User responses:</label>
                                        <Section name={'riba-stage'}>{this.userResponses()}</Section>
                                    </div>
                                    <div className="form-group">
                                        <label>Tool Output:</label>
                                        <Section name={'riba-stage'} >{this.toolOutput()}</Section>
                                    </div>
                                    <div className="form-group">
                                        <label>Tool Objectives:</label>
                                        <Section name={'riba-stage'}>{this.toolObjectives()}</Section>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="row screen__footer">
                        <div className="col-12 d-flex justify-content-center">
                            <button disabled={!PROJECT.getValue(RIBA_STAGE, 'main', 'stage')} onClick={() => {
                                PROJECT.stageComplete(RIBA_STAGE)
                                this.props.onNext(PROJECT);
                            }} className="btn btn--ex-large">Save and proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

RibaOne.defaultProps = {
    onComplete: () => console.log('complete')
};

export default RibaOne;
