import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

function PDFGenerator(el) {
    let pdf = new jsPDF({});
    let project = el.data('project');
    let pages = el.find('.page');
    let progressEl = $('.pdf-download-overlay__percentage')[0];
    let currentTotal = 0;
    function updateProgress(number) {
        if(number < currentTotal) {
            return ;
        }
        currentTotal = Math.ceil(number);
        progressEl.innerHTML = `${currentTotal}%`;

        if(currentTotal === 100) {
            $('.pdf-download-overlay__busy-icon')[0].innerHTML = '<i class="fa fa-check-circle-o" aria-hidden="true"></i>';
            $('.pdf-download-overlay__text')[0].innerHTML = 'Downloading';
            progressEl.innerHTML = ``;

        }

    }

    setTimeout(function () {
        go()
    }, 1000);


    function go() {

        page();
        pagesToPDF();
    }

    function page() {
        pages.each((index, page) => {
            updateProgress((index +1));
            handlePageForPaging(page);
        });
    }

    function getElementsOutOfPage(page) {
        let inner = $(page).find('.page-inner')[0];
        let body = $(page).find('.page-body')[0];

        let height = $(body).height()

        let bodyBottom = $(body).offset().top + height;

        let requiresPaging = [];
        let tableRows = $(inner).find('tr')
        tableRows.each((index, child) => {
            let childBottom = $(child).offset().top + $(child).height();
            if (childBottom >= bodyBottom || index >= 28) {
                requiresPaging.push(child);
            }
        });

        return requiresPaging;
    }

    function insertAfter(el, referenceNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }


    function addElsToNewPage(els, oldPage) {
        let newPage = oldPage.cloneNode(true);
        let inner = $(newPage).find('.page-inner')[0];
        let wrapper = document.createElement('div');
        let random = makeid(10);

        newPage.id = random;
        wrapper.className = 'editable-table table-responsive';
        let table = document.createElement('table');
        table.className = 'table';
        wrapper.appendChild(table);
        let tableBody = document.createElement('tbody');
        table.appendChild(tableBody);

        inner.innerHTML = '';
        els.forEach((el) => {
            tableBody.appendChild(el.cloneNode(true));
            $(el).remove();
        });
        inner.appendChild(wrapper);
        insertAfter(newPage, oldPage);
        getNewPage(random, function (page) {
           handlePageForPaging(page);
        });
    }

    function getNewPage(id, callback){
        let el = document.getElementById(id);
        if(!el) {
            setTimeout(function (){
                // console.log('Got new page: ', el);
                getNewPage(id, callback);
            }, 100);
        }

        callback(el);

    }

    function handlePageForPaging(page) {
        let elsOutOfPage = getElementsOutOfPage(page);
        // console.log(page, elsOutOfPage);

        if (elsOutOfPage.length >= 1) {
            addElsToNewPage(elsOutOfPage, page);
        }

    }

    function pagesToPDF() {
        let pdfPages = $('.page');
        pdfPages.each((index, page) => {

            html2canvas(page).then(canvas => {

                let image = canvas.toDataURL('image/jpeg');
                if (index !== 0) {
                    pdf.addPage();
                }

                let pageHeight = pdf.internal.pageSize.getHeight();
                let pageWidth = pdf.internal.pageSize.getWidth();
                pdf.addImage(image, 'jpeg', 0, 0, pageWidth, pageHeight);
                $(canvas).remove();

                updateProgress( (100 /pdfPages.length ) * (index + 1))
                if ((pages.length - 1) === index) {
                    complete();
                }

            });
        });
    }

    function complete() {
        pdf.save(`${project.name}-overview.pdf`);
    }
}


if (document.getElementById('pdf-download')) {
    PDFGenerator($('#pdf-download'));
}


export default PDFGenerator;
