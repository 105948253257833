import React, {Component} from 'react';
import Lang from '../lib/lang/lang';


class InfoIcon extends Component {

    getLink() {
        let section = Lang.getSection(this.props.section);

        if(section === null) {
            return '';
        }
        return section[this.props.name] ? section[this.props.name] : '';
    }

    render() {
        return (
            <a target={'_blank'} href={this.getLink()} className={'info-icon'}>
                i
            </a>
        );
    }
}

InfoIcon.defaultProps = {
    section : '',
    name : ''
};

export default InfoIcon;
