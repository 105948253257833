import React, {Component} from 'react';
import ContentBase from "./content-base";

class Content extends ContentBase {


    render() {
        return (
            <div className={this.props.className} dangerouslySetInnerHTML={{__html : this.getLang()}}>

            </div>
        );
    }
}

Content.defaultProps = {
    section : {}
}

export default Content;
