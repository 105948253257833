import React, {Component} from 'react';

class CardTotal extends Component {
    render() {

        return (
            <div className={`card-total ${this.props.required === false ? 'disabled' : ''}`}>
                <div className="card-total__value">
                    Credits Total: <span>{this.props.value}</span>
                </div>
                <div className="card-total__sub-value">
                    Credit Aim: { this.props.required === false ? 'N/A' : this.props.aim}
                </div>
            </div>
        );
    }
}

CardTotal.defaultProps = {
    value : 0,
    aim : 1,
    required: true
};

export default CardTotal;
