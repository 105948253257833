import React, {Component} from 'react';
import CreditSummary from "../../components/credit-summary";
import {CreditSummaryStatsFactory} from "../../lib/project/credit-summary-stats-factory";
import {CIRCULARITY_CRITERIA_STAGE} from "./circularity-criteria";
import {DESIGN_FOR_ADAPTABILITY} from "./sub-screens/design-for-adaptability";
import {DESIGN_FOR_DECONSTRUCTION} from "./sub-screens/design-for-deconstruction";
import {CIRCULAR_MATERIAL_SELECTION} from "./sub-screens/circular-material-selection";
import {RESOURCE_EFFICIENCY} from "./sub-screens/resource-efficiency";
import CreditsPrincipleGraph from "../../components/credits-principle-graph";
import CreditsBuildingLayerGraph from "../../components/credits-building-layer-graph";
import Section from "../../lib/lang/section";
import Button from "../../lib/lang/button";

export const CIRCULARITY_CRITERIA_OVERVIEW_STAGE = 'circularity_criteria_overview_stage';

class CircularityCriteriaOverview extends Component {



    render() {
        let factory = new CreditSummaryStatsFactory(this.props.project);
        return (
            <div className={'screen screen--dark-blue-bg'}>
                <div className="container">
                    <div className="row  screen__header">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h1 className="screen__title text-white">Circularity Criteria Overview</h1>
                            <Section name={'circularity-overview'}>
                                <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                            </Section>
                        </div>
                    </div>
                    <div className="screen__body">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><h2>Credit Summary</h2></div>
                                </div>

                                <hr/>
                                <CreditSummary
                                    title={'Design for Adaptability'}
                                    selectedPrinciple={this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', DESIGN_FOR_ADAPTABILITY)}
                                    stats={factory.designForAdaptability()}
                                />
                                <hr/>
                                <CreditSummary
                                    title={'Design for Deconstruction'}
                                    selectedPrinciple={this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', DESIGN_FOR_DECONSTRUCTION)}
                                    stats={factory.designForDeconstruction()}
                                />
                                <hr/>
                                <CreditSummary
                                    title={'Circular Material Selection'}
                                    selectedPrinciple={this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', CIRCULAR_MATERIAL_SELECTION)}
                                    stats={factory.circularMaterialSelection()}
                                />
                                <hr/>
                                <CreditSummary
                                    title={'Resource Efficiency'}
                                    selectedPrinciple={this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', RESOURCE_EFFICIENCY)}
                                    stats={factory.resourceEfficiency()}
                                />
                            </div>

                        </div>

                        <div className="card mt-5 mb-5">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><h2>Awarded Credits by Circularity Principle</h2></div>
                                </div>
                               <CreditsPrincipleGraph
                                   project={this.props.project}
                               />
                            </div>
                        </div>


                        <div className="card mt-5 mb-5">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><h2>Awarded Credits by Building Layer</h2></div>
                                </div>
                                <CreditsBuildingLayerGraph project={this.props.project} />
                            </div>
                        </div>

                    </div>

                    <div className="screen__footer d-flex">
                        <button onClick={() => this.props.onBack(this.props.project)} className="btn btn--ex-large btn--purple mr-auto">Save and return</button>
                        <button onClick={() => {
                            this.props.project.updateValue(CIRCULARITY_CRITERIA_OVERVIEW_STAGE, 'touched', 'complete', "true");
                            this.props.project.stageComplete(CIRCULARITY_CRITERIA_OVERVIEW_STAGE);
                            this.props.onNext(this.props.project);
                        }} className="btn btn--white btn--ex-large ml-auto">Save and proceed</button>
                    </div>

                </div>
            </div>
        );
    }
}

export default CircularityCriteriaOverview;
