import React, {Component} from 'react';
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import CardStatus from "../../../components/card-status";
import ZoneQuestion from "../../../components/zones/zone-question";
import BaseSubScreen, {CARD_TITLE} from "./base-sub-screen";
import {AIM_REQUIRED_QUESTIONS, DESIGN_FOR_ADAPTABILITY_PREFIX} from "../../../lib/project/aim-required-questions";

export const DESIGN_FOR_ADAPTABILITY = 'design_for_adaptability'


class DesignForAdaptability extends BaseSubScreen {

    constructor(props) {
        super(props);
        this.state = {
            open: {}
        };
        this.prefix = DESIGN_FOR_ADAPTABILITY_PREFIX;
        this.namedPrefix = 'DfA'
        this.stage = DESIGN_FOR_ADAPTABILITY;
        this.contentSection = 'design-for-adaptability';
    }


    render() {

        return (
            <div className={'sub-screen'}>
                <div className={`card card-collapsable mb-5 ${this.isOpen('site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('site')} className={'mr-4'}><LargeNavButton /></div>
                        <InfoIcon section={this.contentSection} name={'site-info-link'}/> Site <div className={'ml-auto'}>
                        <CardStatus
                        success={this.allQuestionsAchieved('site', AIM_REQUIRED_QUESTIONS[DESIGN_FOR_ADAPTABILITY_PREFIX].site)}
                        title={CARD_TITLE}/>
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'site',
                            'Capacity of drainage systems suitable for further increase due to climate change',
                            1
                        )
                    }
                    {
                        this.renderQuestion(
                            'site',
                            'Foundations over-sized to accommodate future vertical extensions',
                            2
                        )
                    }

                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('structure')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('structure')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={this.contentSection} name={'site-info-link'}/> Structure <div className={'ml-auto'}><CardStatus
                        success={this.allQuestionsAchieved('structure', AIM_REQUIRED_QUESTIONS[DESIGN_FOR_ADAPTABILITY_PREFIX].structure )}
                        title={CARD_TITLE}/>
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'structure',
                            'Structural grid allows for flexibility and future change of use',
                            3
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Floor loading design criteria allow for flexibility and future change of use',
                            4
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Typical floor-to-ceiling height allows for future change of use',
                            5
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Circulation capacity sufficient for different uses',
                            6
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Structural cores allow for future change of use',
                            7
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Period of structural fire resistance appropriate for different uses',
                            8
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Fire strategy and means of escape are appropriate for different uses',
                            9
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Structure designed to accommodate future expansion of riser space and service voids',
                            10
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Serviceability limit state design allows for future change of use',
                            11
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Connections and components have high durability',
                            12
                        )
                    }

                </div>


                <div className={`card card-collapsable mb-5 ${this.isOpen('skin')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('skin')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={this.contentSection} name={'skin-info-link'}/> Skin <div className={'ml-auto'}><CardStatus
                        success={this.allQuestionsAchieved('skin',AIM_REQUIRED_QUESTIONS[DESIGN_FOR_ADAPTABILITY_PREFIX].skin)}
                        title={CARD_TITLE}/>
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'skin',
                            'Façade insulation suitable for different uses/changing climate',
                            13
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Sound insulation rating appropriate for different uses',
                            14
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Glazing ratios appropriate for different uses',
                            15
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Fire rating of façade is appropriate for different uses',
                            16
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Connections and components have high durability',
                            17
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Environmental design strategies allow for future change of use',
                            18
                        )
                    }
                </div>


                <div className={`card card-collapsable mb-5 ${this.isOpen('services')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('services')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={this.contentSection} name={'services-info-link'}/> Services <div className={'ml-auto'}><CardStatus
                        success={this.allQuestionsAchieved('services', AIM_REQUIRED_QUESTIONS[DESIGN_FOR_ADAPTABILITY_PREFIX].services)}
                        title={CARD_TITLE}/>
                    </div>
                    </div>

                            {
                                this.renderQuestion(
                                    'services',
                                    'Services are easily upgradable for future change of use / changing performance requirements',
                                    19
                                )
                            }
                            {
                                this.renderQuestion(
                                    'services',
                                    'Horizontal and vertical service risers allow for ease of mechanical service upgrades, capacity expansion, and adaptation for change of use.',
                                    20
                                )
                            }

                </div>


                <div className={`card card-collapsable mb-5 ${this.isOpen('space')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('space')} className={'mr-4'}><LargeNavButton/></div>
                        <InfoIcon section={this.contentSection} name={'space-info-link'}/> Space <div className={'ml-auto'}><CardStatus
                        success={this.allQuestionsAchieved('space',AIM_REQUIRED_QUESTIONS[DESIGN_FOR_ADAPTABILITY_PREFIX].space )}
                        title={CARD_TITLE}/>
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'space',
                            'Circulation capacity is sufficient for different uses.',
                            21
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Fire strategy and means of escape are appropriate for different uses',
                            22
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Acoustic performance appropriate for different uses / easily adaptable to changing requirements',
                            23
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Internal partitions are demountable to enable flexibility of use',
                            24
                        )
                    }
                </div>

            </div>
        );
    }
}

export default DesignForAdaptability;
