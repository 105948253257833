export const DEFAULT_PIE_CHART_SETTINGS = {
    data: {
        labels: [],
        datasets: [
            {
                data: []

            }
        ]
    },
    options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: false
    }
}


export const DEFAULT_CHART_COLORS = ['#363B58', '#79bbb7', '#474955', '#746895', '#425b75'];
