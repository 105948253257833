import React, {Component} from 'react';

class ContentBase extends Component {
    getLang() {
        return this.props.section[this.props.name] ? this.props.section[this.props.name] : '';
    }

}

export default ContentBase;
