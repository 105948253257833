class Lang {
    constructor(keys = {}) {
        this.keys = keys;
    }


    getSection(name) {
        let keys = {};
        _.each(this.keys, row => {
            if(row.section === name) {
                keys[row.key] = row.value;
            }
        });
        return _.size(keys) >= 1 ? keys : null;
    }


}

export default new Lang(window.preferences.lang);
