import SectionCollection from "./section-collection";

export default class Stage {
    constructor() {
        this.name = null;
        this.complete = false;
    }

    static make(params) {
        params.sections = new SectionCollection(params.sections ? params.sections.items : []);
        return Object.assign(new Stage, params);
    }

}
