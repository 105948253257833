export default class Section {
    constructor() {
        this.name = null;
        this.values = [];
    }

    getValue(key) {
        let pair = this.getPair(key);
        return pair ? pair.value : '';
    }

    valueExists(key) {
       return !!this.getPair(key);
    }

    getPair(key) {
        return _.find(this.values, value => {
            return value.key === key;
        });
    }

    deleteValues(keys) {
        this.values = this.values.filter(pair => {
           let index =  _.findIndex(keys, key => {
               return key === pair.key;
           });
            return index === -1;
        });
    }

    updateValue(key, value) {
        let pair = this.getPair(key);
        if (pair) {
            pair.value = value;
            return true;
        }

        this.values.push({key: key, value});
        return true;
    }

    static make(params) {
        return Object.assign(new Section(), params);
    }

}
