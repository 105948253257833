import React, {Component} from 'react';
import DesignForAdaptability, {DESIGN_FOR_ADAPTABILITY} from "./sub-screens/design-for-adaptability";
import DesignForDeconstruction, {DESIGN_FOR_DECONSTRUCTION} from "./sub-screens/design-for-deconstruction";
import CircularMaterialSelection, {CIRCULAR_MATERIAL_SELECTION} from "./sub-screens/circular-material-selection";
import ResourceEfficiency, {RESOURCE_EFFICIENCY} from "./sub-screens/resource-efficiency";
import ZonesOverviewPopup from "./zones-overview-popup";
import Section from "../../lib/lang/section";
import Button from "../../lib/lang/button";
import Content from "../../lib/lang/content";
import ReadMore from "../../components/read-more";
import {stickIt} from "../../lib/helpers/sticky";



export const CIRCULARITY_CRITERIA_STAGE = 'circularity_criteria_stage';


class CircularityCriteria extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subScreen: DESIGN_FOR_ADAPTABILITY,
            zonePopupOpen : false
        };
        this.stickyContainer = null;
    }

    subScreen() {

        let props = {
            project: this.props.project,
            onZoneInfoClick : () => {
                this.setState({zonePopupOpen : !this.state.zonePopupOpen})
            }
        };

        switch (this.state.subScreen) {
            case DESIGN_FOR_ADAPTABILITY :
                return <DesignForAdaptability {...props} />;
            case DESIGN_FOR_DECONSTRUCTION :
                return <DesignForDeconstruction {...props} />;
            case CIRCULAR_MATERIAL_SELECTION :
                return <CircularMaterialSelection {...props} />
            case RESOURCE_EFFICIENCY :
                return <ResourceEfficiency {...props} />
        }

    }

    activeTab(screen) {
        return screen === this.state.subScreen ? 'active' : '';
    }


    getContentSection() {
        switch (this.state.subScreen) {
            case DESIGN_FOR_ADAPTABILITY :
                return 'design-for-adaptability';
            case DESIGN_FOR_DECONSTRUCTION :
                return 'design-for-deconstruction';
            case CIRCULAR_MATERIAL_SELECTION :
                return 'circular-material-selection'
            case RESOURCE_EFFICIENCY :
                return 'resource-efficiency'
        }
    }

    componentDidMount() {
        stickIt(this.stickyContainer);
    }

    render() {
        return (
            <div className={'screen'}>
                <ZonesOverviewPopup
                    project={this.props.project}
                    open={this.state.zonePopupOpen}
                    onEditClick={() => {
                        this.props.setScreen(3);
                        this.setState({
                            zonePopupOpen : false
                        });
                    }}
                    onClose={() => this.setState({zonePopupOpen : false})}
                />
                <div className="container">
                    <div className="row screen__header">
                        <div className="col-5">
                            <h1 className="screen__title">
                                Circularity Criteria
                            </h1>
                            <Section name={'circularity-criteria'}>
                                <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                            </Section>
                        </div>
                        <div className="col-7">

                            <ReadMore section={'circularity-criteria'} content={'stage-description'} />
                        </div>
                    </div>
                    <div ref={r => this.stickyContainer = r} className="screen__tabs mb-5 row  container">
                        <div className="col-12   d-flex justify-content-between align-items-center">
                            <div className={'label'}>Circularity Principles:</div>
                            <div>
                                <button onClick={() => this.setState({subScreen: DESIGN_FOR_ADAPTABILITY})}
                                        className={`btn btn--tab ${this.activeTab(DESIGN_FOR_ADAPTABILITY)}`}>Design for
                                    Adaptability
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: DESIGN_FOR_DECONSTRUCTION})}
                                        className={`btn btn--tab ${this.activeTab(DESIGN_FOR_DECONSTRUCTION)}`}>Design for
                                    Deconstruction
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: CIRCULAR_MATERIAL_SELECTION})}
                                        className={`btn btn--tab ${this.activeTab(CIRCULAR_MATERIAL_SELECTION)}`}>Circular
                                    Material Selection
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.setState({subScreen: RESOURCE_EFFICIENCY})}
                                        className={`btn btn--tab ${this.activeTab(RESOURCE_EFFICIENCY)}`}>Resource
                                    Efficiency
                                </button>
                            </div>
                        </div>


                    </div>
                    <div className="screen__tabs mb-5 d-flex align-items-center justify-content-between">
                        <div className="form-group mb-0 pb-0 form-group--reverse">
                            <label htmlFor="">Circularity Principle Aim</label>
                            <select name={'circularity-principle-aim'}
                                    value={this.props.project.getValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', this.state.subScreen)}
                                    onChange={e => {
                                        this.props.project.updateValue(CIRCULARITY_CRITERIA_STAGE, 'circularity-principle-aim', this.state.subScreen, e.target.value)
                                    }} className="form-control"
                            >
                                <option value="basic">Basic Circularity</option>
                                <option value="partial">Partial Circularity</option>
                                <option value="full">Full Circularity</option>
                            </select>
                        </div>
                        <div>
                            <Section name={this.getContentSection()}>
                                <Button name={'help-link'} className="btn btn--dark-green">Help</Button>
                            </Section>
                            <Section name={this.getContentSection()}>
                                <Button name={'additional-info-link'} className="btn ml-2">Additional Info</Button>
                            </Section>

                        </div>
                    </div>
                    <div className="screen__body">
                        {
                            this.subScreen()
                        }
                    </div>
                    <div className="screen__footer d-flex">
                        <button onClick={() => this.props.onBack(this.props.project)} className="btn btn--ex-large btn--purple mr-auto">Save and
                            return
                        </button>
                        <button onClick={() => {
                            this.props.project.stageComplete(CIRCULARITY_CRITERIA_STAGE);
                            this.props.onNext(this.props.project);
                        }} className="btn btn--ex-large ml-auto">Save and proceed
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CircularityCriteria;
