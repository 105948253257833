
export function stickIt(el) {
    const trigger = el.offsetTop;

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > trigger) {
            el.classList.add("sticky-active");
        } else {
            el.classList.remove("sticky-active");
        }
    });
}


export default function sticky() {
    const els = document.getElementsByClassName('sticky');

    Array.from(els).forEach(el => stickIt(el));

}


sticky();
