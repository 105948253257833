import React, {Component} from 'react';

class CardStatus extends Component {
    render() {
        return (
            <div className={'card-status'}>
                <div className="card-status__title">{this.props.title}</div>
                <div className={`card-status__value ${this.props.success ? 'success' : ''}`}>{this.props.success ? 'Achieved' : 'Not Achieved'}</div>
            </div>
        );
    }
}

CardStatus.defaultProps = {
    title : '',
    success: false
};

export default CardStatus;
