import React, {Component} from 'react';
import {CreditSummaryStats} from "./credit-summary-stats";

class CreditSummary extends Component {

    round(number) {
        return Math.round(number);
        // return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    getRow(title, type) {
        let s = this.props.stats;
        return (
            <tr>
                <td>{title}</td>
                <td>{s[type].credit_total ? this.round(s[type].credit_total) : 0}</td>
                <td>{s[type].percentage_selected_aim ? this.round(s[type].percentage_selected_aim) : 0}%</td>
                <td>{s[type].percentage_total_credits ? this.round(s[type].percentage_total_credits) : 0}%</td>
                <td>{s[type].awarded_circularity_rating ? this.getAwardName(s[type].awarded_circularity_rating) : 'No Rating Achieved'}</td>
            </tr>
        );
    }

    getAwardName(rating) {
        switch (rating) {
            case ('full') :
                return 'Full Circularity';
            case('partial') :
                return 'Partial Circularity';
            case('basic') :
                return 'Basic Circularity';
            default :
                return 'No Rating Achieved';
        }
    }


    render() {
        return (
            <div className={'credit-summary'}>


                <div className="row mt-5">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <h5 className="dark-green-text semi-heavy-text">{this.props.title}</h5>
                        <div className={'d-flex align-items-center '}>
                            <div className={'mr-2'}>Selected Circularity Aim</div>
                            <div
                                className="btn btn--light-green">{this.getAwardName(this.props.selectedPrinciple)}</div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Building Layer</th>
                                    <th>Credit Total</th>
                                    <th>Percentage of Selected Circularity Aim</th>
                                    <th>Percentage of Total Credits</th>
                                    <th>Awarded Circularity Rating</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.getRow('Site', 'site')
                                }
                                {
                                    this.getRow('Structure', 'structure')
                                }
                                {
                                    this.getRow('Skin', 'skin')
                                }
                                {
                                    this.getRow('Services', 'services')
                                }
                                {
                                    this.getRow('Space', 'space')
                                }

                                <tr className={'highlight'}>
                                    <td>Total</td>
                                    <td>{this.round(this.props.stats.totals.credit_total)}</td>
                                    <td>{this.round(this.props.stats.totals.percentage_selected_aim)}%</td>
                                    <td>{this.round(this.props.stats.totals.percentage_total_credits)}%</td>
                                    <td>{this.getAwardName(this.props.stats.totals.awarded_circularity_rating)}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CreditSummary.defaultProps = {
    title: '',
    selectedPrinciple: '',
    stats: new CreditSummaryStats()
}

export default CreditSummary;
