import React, {Component} from 'react';
import {CIRCULAR_ECONOMY_STATEMENT_STAGE} from "./circular-economy-statement";
import Section from "../../lib/lang/section";
import Content from "../../lib/lang/content";

export const PROJECT_SUMMARY_STAGE = 'project_summary_stage';

class ProjectSummary extends Component {
    render() {
        return (
            <div className={'project-summary screen screen--full-height screen--light-green-bg'}>
                <div className="container">
                    <div className="row  screen__header mb-2">
                        <div className="col-12 d-flex flex-column align-items-start">
                            <h1 className="screen__title">Project Summary</h1>
                            <h4 className={'text-white'}>
                                <Section name={'project-summary'}>
                                    <Content name={'message'}/>
                                </Section>
                            </h4>
                            <a target={'_blank'} href={`/projects/${this.props.project.id}/summary-pdf-download`} className="btn btn--ex-large btn--white mt-5">Generate PDF</a>
                        </div>
                    </div>
                    <div className="screen__footer d-flex mt-5">
                        <button onClick={() => this.props.onBack(this.props.project)}
                                className="btn btn--ex-large btn--purple mr-auto">Save and return
                        </button>
                        <button onClick={() => {
                            this.props.project.updateValue(PROJECT_SUMMARY_STAGE, 'touched', 'complete', "true");
                            this.props.project.stageComplete(PROJECT_SUMMARY_STAGE);
                            this.props.onNext(this.props.project);
                        }} className="btn btn--ex-large ml-auto">Save and Close Project
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectSummary;
