export const DESIGN_FOR_ADAPTABILITY_PREFIX = 'dfa';
export const DESIGN_FOR_DECONSTRUCTION_PREFIX = 'dfd';
export const CIRCULAR_MATERIAL_SELECTION_PREFIX = 'cms';
export const RESOURCE_EFFICIENCY_PREFIX = 're';

export const AIM_REQUIRED_QUESTIONS = {
    [DESIGN_FOR_ADAPTABILITY_PREFIX]: {
        site: {
            basic: [1],
            partial: [1],
            full: [1, 2]
        },
        structure: {
            basic: [3, 4, 5, 6, 7, 8],
            partial: [3, 4, 5, 6, 7, 8, 9, 10, 11],
            full: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        skin: {
            basic: [13, 14, 15],
            partial: [13, 14, 15, 16],
            full: [13, 14, 15, 16, 17, 18]

        },
        services : {
            basic: [19, 20],
            partial: [19, 20],
            full: [19, 20]
        },
        space : {
            basic: [21],
            partial: [21, 22, 23],
            full: [21, 22, 23, 24]
        }

    },
    [DESIGN_FOR_DECONSTRUCTION_PREFIX]: {
        site: {
            basic: [1],
            partial: [1],
            full: [1]
        },
        structure: {
            basic: [2, 3, 4],
            partial: [2, 3, 4, 5],
            full: [2, 3, 4, 5, 6]
        },
        skin: {
            basic: [7, 8],
            partial: [7, 8, 9],
            full: [7, 8, 9, 10, 11]

        },
        services : {
            basic: [12, 13],
            partial: [12,13,14],
            full: [12, 13, 14, 15]
        },
        space : {
            basic: [16, 17],
            partial: [16,17,18],
            full: [16, 17, 18, 19]
        }

    },
    [CIRCULAR_MATERIAL_SELECTION_PREFIX]: {
        site: {
            basic: [1],
            partial: [1],
            full: [1]
        },
        structure: {
            basic: [2,3,4,7],
            partial: [2,3,4,5,6,7,8],
            full: [2,3,4,5,6,7,8]
        },
        skin: {
            basic: [9,10,11],
            partial: [9,10,11,13],
            full: [9,10,11,12,13,14]
        },
        services : {
            basic: [15,16,17],
            partial: [15,16,17,18],
            full: [15,16,17,18,19,20]
        },
        space : {
            basic: [21,22,23],
            partial: [21,22,23,26],
            full: [21,22,23,24,25,26]
        }
    },
    [RESOURCE_EFFICIENCY_PREFIX]: {
        site: {
            basic: [1, 2],
            partial: [1,  2, 3],
            full: [1,  2, 3, 4]
        },
        structure: {
            basic: [5,  6, 7, 8],
            partial: [5,  6, 7, 8],
            full: [5,  6, 7, 8]
        },
        skin: {
            basic: [9, 10],
            partial: [9,  10],
            full: [9,  10, 11]
        },
        services : {
            basic: [12, ],
            partial: [12,  13],
            full: [12,  13, 14]
        },
        space : {
            basic: [15],
            partial: [15,  16],
            full: [15, 16, 17]
        }
    }
}
