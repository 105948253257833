import {StageCollection} from "./stage-collection";
import Stage from "./stage";
import Section from "./section";
import SectionCollection from "./section-collection";

export default class Project {

    constructor() {
        this.id = null;
        this.stages = new StageCollection([]);
        this.onUpdate = () => console.log('Project Updated');
    }


    serialize() {
        return JSON.parse(JSON.stringify(this));
    }

    valueExists(
        stage = '',
        section = '',
        key = '',
    ) {
        section = this.getSection(stage, section);
        if (!section) {
            return false;
        }

        return section.valueExists(key);
    }

    getValue(
        stage = '',
        section = '',
        key = '',
        defaultValue = ''
    ) {

        section = this.getSection(stage, section);
        if (!section) {
            return defaultValue;
        }
        let value = section.getValue(key);

        return value ? value : defaultValue;
    }


    getSection(
        stage = '',
        section = '',
    ) {
        stage = this.stages.find(stage);
        if (!stage) {
            return null;
        }
        return stage.sections.find(section);

    }

    deleteValues(
        stageName = '',
        sectionName = '',
        keys = [],
    ) {

        let section = this.getSection(stageName, sectionName);
        if (!section) {
            return false;
        }
        section.deleteValues(keys);
        this.fireUpdateEvent();
    }

    fireUpdateEvent() {
        this.onUpdate(this);
    }

    updateValue(
        stageName = '',
        sectionName = '',
        key = '',
        value = '',
    ) {

        let stage = this.stages.find(stageName);

        if (!stage) {
            this.stages.add(Stage.make({
                name: stageName,
                sections: new SectionCollection([
                    Section.make({
                        name: sectionName,
                        values: [{key: key, value: value}]
                    })
                ])
            }));
            this.fireUpdateEvent();
            return true;
        }

        stage.complete = false;

        let section = stage.sections.find(sectionName);

        if (!section) {
            stage.sections.add(Section.make({
                name: sectionName,
                values: [{key: key, value: value}]
            }));
            this.fireUpdateEvent();
            return true;
        }

        let success = section.updateValue(key, value);

        if (success) {
            this.fireUpdateEvent();

            return true;
        }
        return false;
    }

    stageComplete(stageName) {
        let stage = this.stages.find(stageName);
        if (!stage) {
            throw new Error('Stage could not be found to complete');
        }
        stage.complete = true;
        this.fireUpdateEvent();
        return true;
    }

    static make(params = {}) {

        params.stages = new StageCollection(params.stages ? params.stages.items : []);
        return Object.assign(new Project(), params);
    }

    isStageComplete(stageName) {
        let stage = this.stages.find(stageName);
        return stage ? stage.complete : false;
    }

    areStagesComplete(stages = []) {
        for (let i = 0; i < stages.length; i ++) {
            if(!this.isStageComplete(stages[i])) {
                return false;
            }
        }

        return true;
    }


}
