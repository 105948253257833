import React, {Component} from 'react';
import BaseSubScreen, {CARD_TITLE} from "./base-sub-screen";
import LargeNavButton from "../../../components/large-nav-button";
import InfoIcon from "../../../components/info-icon";
import CardStatus from "../../../components/card-status";
import {AIM_REQUIRED_QUESTIONS, DESIGN_FOR_DECONSTRUCTION_PREFIX} from "../../../lib/project/aim-required-questions";


export const DESIGN_FOR_DECONSTRUCTION = 'design_for_deconstruction';

class DesignForDeconstruction extends BaseSubScreen {

    constructor(props) {
        super(props);
        this.state = {
            open: {}
        };

        this.stage = DESIGN_FOR_DECONSTRUCTION;
        this.prefix = DESIGN_FOR_DECONSTRUCTION_PREFIX;
        this.namedPrefix = 'DfD'
        this.contentSection = 'design-for-deconstruction';

    }


    render() {
        let q = AIM_REQUIRED_QUESTIONS[DESIGN_FOR_DECONSTRUCTION_PREFIX];
        return (
            <div className={'sub-screen'}>

                <div className={`card card-collapsable mb-5 ${this.isOpen('site')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('site')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'site-info-link'}/> Site <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('site', q.site)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'site',
                            'Foundation design is considered to be reversible',
                            1
                        )
                    }


                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('structure')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('structure')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'structure-info-link'}/> Structure <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('structure', q.structure)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'structure',
                            'Reversible, mechanical connections are used instead of chemical alternatives\n',
                            2
                        )
                    }

                    {
                        this.renderQuestion(
                            'structure',
                            'Access to structural connections provided to allow for ease of deconstruction and a high percentage recovery of material',
                            3
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'The number of different types of structural connections are minimised',
                            4
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'A deconstruction plan has been produced',
                            5
                        )
                    }
                    {
                        this.renderQuestion(
                            'structure',
                            'Material passport style inventory recorded',
                            6
                        )
                    }


                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('skin')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('skin')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'skin-info-link'}/> Skin <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('skin', q.skin)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'skin',
                            'Reversible, mechanical connections are used instead of chemical alternatives',
                            7
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Access to façade connections provided to allow for ease of dismount and ensure high percentage recovery of material',
                            8
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Number of different types of connections to façades minimised',
                            9
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Façade replacement or upgrade strategy in place\n',
                            10
                        )
                    }
                    {
                        this.renderQuestion(
                            'skin',
                            'Material passport style inventory recorded',
                            11
                        )
                    }
                </div>

                <div className={`card card-collapsable mb-5 ${this.isOpen('services')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('services')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'services-info-link'}/> Services <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('services', q.services)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'services',
                            'Reversible, mechanical connections are used instead of chemical alternatives',
                            12
                        )
                    }{
                    this.renderQuestion(
                        'services',
                        'Access to services is provided to allow for ease of deconstruction, upgrade and a high percentage recovery of material',
                        13
                    )
                }{
                    this.renderQuestion(
                        'services',
                        'Number of different types of connections to services minimised',
                        14
                    )
                }{
                    this.renderQuestion(
                        'services',
                        'Material passport style inventory recorded',
                        15
                    )
                }

                </div>
                <div className={`card card-collapsable mb-5 ${this.isOpen('space')}`}>
                    <div className="card-header card-header--dark-green">
                        <div onClick={() => this.toggleOpen('space')} className={'mr-4'}>
                            <LargeNavButton/>
                        </div>
                        <InfoIcon section={this.contentSection} name={'space-info-link'}/> Space <div className={'ml-auto'}>
                        <CardStatus
                            success={this.allQuestionsAchieved('space', q.space)}
                            title={CARD_TITLE}
                        />
                    </div>
                    </div>
                    {
                        this.renderQuestion(
                            'space',
                            'Reversible, mechanical connections are used instead of chemical alternatives',
                            16
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Access to internal connections / fixings provided to allow for ease of deconstruction and ensure high percentage recovery of material',
                            17
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Number of different types of connections to internal spaces minimised',
                            18
                        )
                    }
                    {
                        this.renderQuestion(
                            'space',
                            'Material passport style inventory recorded',
                            19
                        )
                    }

                </div>

            </div>
        );
    }
}

export default DesignForDeconstruction;
